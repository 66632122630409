import React from 'react';


export default function TabelButoaneFacturiSiPlati() {
  return (
<div style={{padding: '50px', display: "flex", justifyContent: "center", alignItems: "center", border: ' 0' }}>
<iframe title="Feedback Form" class="freshwidget-embedded-form" id="freshwidget-embedded-form" src="https://premierenergy.freshdesk.com/widgets/feedback_widget/new?&widgetType=embedded&formTitle=Formular+contact&submitTitle=Trimite&submitThanks=Multumim!&screenshot=Nu&searchArea=no&widgetView=no&helpdesk_ticket[ticket_type]=Confirmare plata" scrolling="no" height="600px" width="100%" frameborder="0" />
</div>
  );
}
 
