import * as React from 'react';
import { Modal, Grid, Button, IconButton, Box, TextField, CircularProgress, makeStyles, Typography } from '@material-ui/core';
import { ControlPoint } from '@material-ui/icons';
import { datePortal } from '../api/api';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import withAppInsights from '../AppInsights';
import { styled } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  boxStyle: {
  backgroundColor: 'background.paper',
  border: '2px solid #FF9241',
  boxShadow: '2px',
  paddingTop: 2,
  px: 4,
  pb: 3,
  [theme.breakpoints.down('xs')]: {
    display: 'inline-block',
   },
   [theme.breakpoints.down('md')]: {
    display: 'inline-block',
   },
   [theme.breakpoints.down('lg')]: {
    display: 'inline-block',
   },
  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth:'50%',
  width: '50%',
  bgcolor: 'background.paper',
  border: '2px solid #FF9241',
  boxShadow: 24,
  p: 4,
  maxWidth: '20rem'
};

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#FF9241'),
  borderRadius: '',
  backgroundColor: '#FF9241',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#ffaf74',
  },
  '&:focus': {
    backgroundColor: '#ffaf74',
    textColor: '#FFFFFF',

  },
}));

// const xsStyle = {
//   position: 'absolute',
//   top: '25%',
//   left: '25%',
//   right: '25%',
//   margin: 'auto',
//   justifyContent: 'center',
//   alignContent: 'center',
// };

// const mdStyle = {
//   position: 'absolute',
//   top: '25%',
//   left: '25%',
//   right: '25%',
// }

// const lgStyle = {
//   position: 'absolute',
//   top: '50%',
//   left: '50%',
//   right: '50%',
// }



const operatori = ["ADS","CON","CGZ","CPL","DEG","DZP","DGS","DGV","ESV","GVS","GNE","GZV","GZM","HRG","ICC","MMD","MGZ","MGA","MHG","MHC","NGZ","NPG","OLG","PEY","PRI","PRG","RGZ","SLG","TLG"];




function AdaugareLocConsum(parametri){

  function handleOnChange (tip,valoare) {
    const locConsum=parametri.locConsum;
    if (tip==="cnpCui"){
      locConsum.cnpCui=valoare;
    }
    if (tip==="locConsum"){
      locConsum.locConsum=valoare.toUpperCase();
    }
      
    parametri.setLocConsum(locConsum);
  }

  

    return (
        <div>
            <div>
            <TextField
            required
            error = { parametri.adaugaEroareCnp ? true : false }
            style={{textAlign: 'center'}}
            id="inputCNP"
            label="CNP sau CUI(fara RO)"
            onChange={ (e)=> handleOnChange("cnpCui",e.target.value)}
            />
            </div>
            <div>
            <TextField
            required
            helperText="De forma: PEYIFOTO12345"
            error = { parametri.adaugaEroareClc ? true : false }
            style={{textAlign: 'center'}}
            id="inputCodLocConsum"
            label="Cod loc consum(CLC)"
            inputProps={{ style: { textTransform: "uppercase" } }}
            onChange={ (e)=> handleOnChange("locConsum",e.target.value)}
            />
            </div>
        </div>

    )

}




function ModalAdaugaLocConsum(parametri) {
  const [locConsumNou,setLocConsumNou] = React.useState({});
  const [eroare,setEroare] = React.useState({"status":false});
  const [open, setOpen] = React.useState(false);
  const [afiseazaButonAdauga,setAfiseazaButonAdauga]=React.useState(true);
  const [afiseazaLoaderSave,setAfiseazaLoaderSave]=React.useState(false);
  const [adaugaEroareCnp,setAdaugaEroareCnp]=React.useState(false);
  const [adaugaEroareClc,setAdaugaEroareClc]=React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    setEroare(false);
    setAfiseazaButonAdauga(true);
    setAdaugaEroareClc(false);
    setAdaugaEroareCnp(false);
  };



  const adaugaLocul = async function () {

     setAdaugaEroareCnp(false);
     setAdaugaEroareClc(false);

    if(!locConsumNou.cnpCui && !locConsumNou.locConsum){
      setAdaugaEroareCnp(true);
      setAdaugaEroareClc(true);
    }
   else if(!locConsumNou.locConsum ){
      setAdaugaEroareClc(true);
    }
    else if(!locConsumNou.cnpCui ){
      setAdaugaEroareCnp(true);
    }
    else if(operatori.includes(locConsumNou.locConsum.substring(0,3)) === false){
      setAdaugaEroareClc(true);
    }
    else{
    let locuriConsum;
  
    try {
      setAfiseazaButonAdauga(false);
      setAfiseazaLoaderSave(true);
      locuriConsum= await datePortal("adaugareLocConsum",locConsumNou,setEroare)
      let locuriConsumUltimUpdate ={ dataUltimaActualizare: moment().format("YYYYMMDDhh"),
        "locuriConsum":locuriConsum
      }
      localStorage.setItem("locuriConsum",JSON.stringify(locuriConsumUltimUpdate))
      setAfiseazaLoaderSave(false);
      parametri.setLocuriConsumUtilizator();
      handleClose();
      setAfiseazaButonAdauga(true);
    } catch (error) {
      let eroare = JSON.parse(error.message);
      setEroare({"status":true,"mesaj":eroare.mesaj})
      setAfiseazaLoaderSave(false);
    }
    setAdaugaEroareClc(false);
    setAdaugaEroareCnp(false);
    setLocConsumNou({});
    }
    };

    const classes = useStyles();

  return (
    <div >
      <ColorButton  style={{ height: 40, color: '#FFFFFF'}} startIcon={<ControlPoint />} onClick={handleOpen}>Adauga loc consum</ColorButton>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Grid container>
        <Grid item >
        <Box sx={style} xs={{width:'50%'}} md={{width:'40%'}} lg={{width:'40%'}}>
        <IconButton style={{ color: '#000000', position: 'absolute', right:'1vw', top:'1px' }} color="primary" aria-label="add to shopping cart">
          <CloseIcon onClick={handleClose} />
        </IconButton>
          <Typography style={{textAlign:'center'}} id="parent-modal-title" variant="h6" component="h2">
            Introduceti urmatoarele informatii pentru a va adauga un loc de consum:
            </Typography>
          <p style={{textAlign: 'center'}} id="parent-modal-description">
            <AdaugareLocConsum 
            locConsum={locConsumNou}
            setLocConsum={setLocConsumNou}
            adaugaEroareCnp={adaugaEroareCnp}
            adaugaEroareClc={adaugaEroareClc}
            />
          </p>
          <div style={{textAlign: 'right', border: '20px'}}>
          {afiseazaButonAdauga && <ColorButton style={{color: '#FFFFFF'}}  onClick={adaugaLocul}> Adauga</ColorButton>}
          {afiseazaLoaderSave && <CircularProgress />}
          </div>
          {eroare.status && <h4 style={{textAlign:'center'}}> {eroare.mesaj+"."} </h4>}
          </Box>
        </Grid>
        </Grid>
      </Modal>
    </div>
  );
}


export default withAppInsights(ModalAdaugaLocConsum);