import * as React from 'react';
import { Modal,Grid,  Button, Box, Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '40%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};



export default function BasicModal() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <div>
      <Button style={{marginTop: '200px'}} onClick={handleOpen}>Open modal</Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style} width={{xs:'40%', md:'30%', lg:'30%'}}>
          <Typography id="modal-modal-title" variant="h6" component="h2">
            Text in a modal
          </Typography>
          <Typography id="modal-modal-description" sx={{ mt: 2 }}>
            Duis mollis, est non commodo luctus, nisi erat porttitor ligula.
          </Typography>
        </Box>
      </Modal>
      <Grid container spacing={isSmall?0:5} columns={{ xs: 4, sm: 8, md: 12 }}>

      <Grid item xs={2} sm={4} md={4}>
      <div style={{backgroundColor:'red'}}>xs=2</div>
    </Grid>  
    <Grid item xs={2} sm={4} md={4}>
      <div style={{backgroundColor:'green'}}>xs=2</div>
    </Grid>  
    <Grid item xs={2} sm={4} md={4}>
      <div style={{backgroundColor:'blue'}}>xs=2</div>
    </Grid>  
    <Grid item xs={2} sm={4} md={4}>
      <div style={{backgroundColor:'purple'}}>xs=2</div>
    </Grid>

</Grid>
    </div>
  );
}