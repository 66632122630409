import { Exception } from "@microsoft/applicationinsights-web";
import axios from "axios";


const apiHost=process.env.REACT_APP_DATA_HOST


// https://peremierenergy-portalclient.azurewebsites.net/api/sold
async function redirectEuPlatesc(eroare,body,config,setEroare){

  const path= apiHost+'/RedirectEuPlatesc'
  const data = await axios.post(path,JSON.stringify(body),config)  
  .then(response => {
    return response.data
      
  }) 
  .catch((error) => {
      if (error &&  error.response && error.response.data){
        eroare={
          "status":true,
          "mesaj":error.response.data.eroare
        }
      }
      else {
        eroare={
          "status":true,
          "mesaj":"A apărut o eroare."
        };
        console.log(error);
      }  
})
if (!eroare.status){
  return data;
}
else {
  return eroare;
}
}

async function adaugareLocConsum(eroare,body,config,setEroare){

  const path= apiHost+'/locConsum';

    await axios.post(path,body,config)
    .then(response => {
      return response.data
        
    })
    .catch(
      (error) => {
        if (error &&  error.response && error.response.data){
          eroare={
            "status":true,
            "mesaj":error.response.data.eroare
          }
        }
        else {
          eroare={
            "status":true,
            "mesaj":"A apărut o eroare."
          };
          console.log(error);
        } 
        throw new Error(JSON.stringify(eroare)); 
    }
    )
  
}


async function aduLocuriConsumCuAdresa(eroare,config,setEroare){

  const path= apiHost+'/locConsum'
  const configGetAddress=JSON.parse(JSON.stringify(config));
  configGetAddress.params ={"cuAdresa": "true"}
    const data = await axios.get(path,configGetAddress)
    .then(response => {
      return response.data
    })    
    .catch((error) => { 
        if (error &&  error.response && error.response.data){
          eroare={
            "status":true,
            "mesaj":error.response.data.eroare
          }
        }
        else {
          eroare={
            "status":true,
            "mesaj":"A apărut o eroare."+error
          };
        }  
        throw new Error(JSON.stringify(eroare)); 
    })
    return data;
    
}




async function aducConsum(eroare,config,setEroare){

  const path= apiHost+'/consum'
  const data = await axios.get(path,config)
  .then(response => {
    return response.data
      
  }) 
  .catch((error) => {
      if (error &&  error.response && error.response.data){
        eroare={
          "status":true,
          "mesaj":error.response.data.eroare
        }
      }
      else {
        eroare={
          "status":true,
          "mesaj":"A apărut o eroare."
        };
        console.log(error);
      }  
})
setEroare(eroare);
if (!eroare.status){
  return [...data];
}
}

async function aducFacturile(eroare,config,setEroare){

  const path= apiHost+'/facturi?Versiunea=1'
  try {
    const data =await axios.get(path,config)
    .then(res=>{
      return [...res.data];
    }).catch(error =>{
      if (error &&  error.response && error.response.data){
        eroare={
          "status":true,
          "mesaj":error.response.data.eroare
        }
      }
      else {
        eroare={
          "status":true,
          "mesaj":"A apărut o eroare."+error
        };
      }  
      throw new Error(JSON.stringify(eroare));
      
    });
    return data;
    
  } catch (error) {
    if (error &&  error.response && error.response.data){
      eroare={
        "status":true,
        "mesaj":error.response.data.eroare
      }
    }
    else {
      eroare={
        "status":true,
        "mesaj":"A apărut o eroare."+error
      };
    }  
    throw new Error(JSON.stringify(eroare));
    }
    
    
}

function UserException(message) {
  this.message = message;
  this.name = 'UserException';
}



async function aduFacturaPDF(eroare,nrFactura,config,setEroare){



  const path= apiHost+'/facturi/'+nrFactura+'/pdf';
  const data = await axios.get(path,config)    
  .then(response => {
    return response.data
      
  }) 
  .catch((error) => {
      if (error &&  error.response && error.response.data){
        eroare={
          "status":true,
          "mesaj":error.response.data.eroare
        }
      }
      else {
        eroare={
          "status":true,
          "mesaj":"A apărut o eroare."
        };
        console.log(error);
      }  
})
setEroare(eroare);
if (!eroare.status){
  return data;
} else {
  throw new UserException(eroare) ;
}
}


async function aducSold(eroare,config,setEroare){

  const path= apiHost+'/sold?Versiunea=1'
  const data = await axios.get(path,config)    
  .then(response => {
    return response.data
      
  }) 
  .catch((error) => {
      if (error &&  error.response && error.response.data){
        eroare={
          "status":true,
          "mesaj":error.response.data.eroare
        }
      }
      else {
        eroare={
          "status":true,
          "mesaj":"A apărut o eroare."
        };
        console.log(error);
      }  
})
setEroare(eroare);
if (!eroare.status){
  return [...data];
}
}

async function stergeLocConsum(eroare,body,config,setEroare){

  const path= apiHost+'/locConsum'
  // const data = await axios.delete(path,JSON.stringify(body),config)   
  const configLocal = JSON.parse(JSON.stringify(config));
  configLocal.data = body
  const data = await axios.delete(path,configLocal)  
  .then(response => {
    return response.data
      
  }) 
  .catch((error) => {
      if (error &&  error.response && error.response.data){
        eroare={
          "status":true,
          "mesaj":error.response.data.eroare
        }
      }
      else {
        eroare={
          "status":true,
          "mesaj":"A apărut o eroare."
        };
        console.log(error);
      }    
})
setEroare(eroare);
if (!eroare.status){
  return [...data];
}
}


async function transmitereIndex(eroare,body,config,setEroare){

  const path= apiHost+'/index';

  const data = await axios.post(path,body,config)
    .then(response => {
      return response.data
        
    })
    .catch((error) => {
        if (error &&  error.response && error.response.data){
          eroare={
            "status":true,
            "mesaj":error.response.data
          }
        }
        else {
          eroare={
            "status":true,
            "mesaj":"A apărut o eroare."
          };
          console.log(error);
        }
        throw new Error(JSON.stringify(eroare));  
    })
    setEroare(eroare);
    if (!eroare.mesaj){
      return data;
    }
  
}

async function activareFacturaElectronica(eroare,body,config,setEroare){

  const path= apiHost+'/EFactura';

  const data = await axios.post(path,body,config)
    .then(response => {
      return response.data
        
    })
    .catch((error) => {
        if (error &&  error.response && error.response.data){
          eroare={
            "status":true,
            "mesaj":error.response.data.eroare
          }
        }
        else {
          eroare={
            "status":true,
            "mesaj":"A apărut o eroare."
          };
          console.log(error);
        }  
    })
    setEroare(eroare);
    if (!eroare.mesaj){
      return data;
    }
  
}

async function modificareFacturaElectronica(eroare,body,config,setEroare){

  const path= apiHost+'/EFactura';

  const data = await axios.post(path,body,config)
    .then(response => {
      return response.data
        
    })
    .catch((error) => {
        if (error &&  error.response && error.response.data){
          eroare={
            "status":true,
            "mesaj":error.response.data.eroare
          }
        }
        else {
          eroare={
            "status":true,
            "mesaj":"A apărut o eroare."
          };
          console.log(error);
        }  
    })
    setEroare(eroare);
    if (!eroare.mesaj){
      return data;
    }
  
}

function uuidv4() {
  return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}


export async function  datePortal(actiune,body,setEroare) {
  const token = localStorage.getItem('id_token');
  const config = {
    headers: { 'premier-auth' : 'Bearer '+ token,
               'x-pe-request-id': uuidv4()
  }
  };
  let eroareLocal ={"status":false}
    if (actiune==="adaugareLocConsum"){
     return  await adaugareLocConsum(eroareLocal,body,config,setEroare)
  }
  else if (actiune==="aduLocuriConsumCuAdresa"){
    return  await aduLocuriConsumCuAdresa(eroareLocal,config,setEroare)
  }
  else if (actiune==="aducConsum"){
    return  await aducConsum(eroareLocal,config,setEroare)
  }
  else if (actiune==="aducFacturile"){
    return await aducFacturile(eroareLocal,config,setEroare)
  }
  else if (actiune==="aduFacturaPDF"){
    return  await aduFacturaPDF(eroareLocal,body,config,setEroare)
  }
  else if (actiune==="aducSold"){
    return  await aducSold(eroareLocal,config,setEroare)
  }
  else if (actiune==="redirectEuPlatesc"){
    return  await redirectEuPlatesc(eroareLocal,body,config,setEroare)
  }
  else if (actiune==="stergeLocConsum"){
    return  await stergeLocConsum(eroareLocal,body,config,setEroare)
  }
  else if(actiune==="transmitereIndex"){
    return await transmitereIndex(eroareLocal,body,config,setEroare)
  }
  else if(actiune==="activareFacturaElectronica"){
    return await activareFacturaElectronica(eroareLocal,body,config,setEroare)
  }
  else if(actiune==="modificareFacturaElectronica"){
    return await modificareFacturaElectronica(eroareLocal,body,config,setEroare)
  }


  
}