import React from 'react';
import withAppInsights from '../AppInsights';
import {appInsights} from '../AppInsights';
import {userDinToken} from '../security/sec'
import { styled } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { datePortal } from '../api/api';
import moment from "moment";
import { Switch, IconButton, Box, Modal, Select, Grid,FormGroup,FormControlLabel,createTheme, TextField, Tooltip, Typography, InputLabel, FormControl, MenuItem,  Button, CardContent, Card, CardActions } from '@material-ui/core';

const MySwitch = styled(Switch)({
  "&.MuiSwitch-root .Mui-checked": {
   color: "#FF9241"
  },
  "& .MuiSwitch-switchBase.Mui-checked+.MuiSwitch-track": {
    backgroundColor: '#FF9241'
  }
});

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#FF9241'),
  borderRadius: '',
  backgroundColor: '#FF9241',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#ffaf74',
  },
  '&:focus': {
    backgroundColor: '#ffaf74',
    textColor: '#FFFFFF',

  },
}));

const ColorButtonDisable = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#FF9241'),
  borderRadius: '',
  backgroundColor: '#A8A8A8',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#A8A8A8',
  },
  '&:focus': {
    backgroundColor: '#A8A8A8',
    textColor: '#FFFFFF',

  },
}));

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  minWidth:'50%',
  width: '50%',
  bgcolor: 'background.paper',
  border: '2px solid #FF9241',
  boxShadow: 24,
  p: 4,
  maxWidth: '20rem'
};



function FacturaElectronica(parametri){
    const properties = {userId : userDinToken()}
    const [partenerSelectat, setPartenerSelectat] = React.useState(parametri.locuriConsumUtilizator[0]?parametri.locuriConsumUtilizator[0].PoD:'');
    const [openEmail, setOpenEmail] = React.useState(false);
    const [changeEmail, setChangeEmail] = React.useState(false);
    const [openModal1, setOpenModal1] = React.useState(false);
    const [openModal2, setOpenModal2] = React.useState(false);
    const [infoFacturaElectronicaPartener, setInfoFacturaElectronicaPartener] = React.useState([]);
    const [emailModificare, setEmailModificare] = React.useState('');
    const [emailConfirmareModificare, setEmailConfirmareModificare] = React.useState('');
    const [emailActivare, setEmailActivare] = React.useState('');
    const [emailConfirmareActivare, setEmailConfirmareActivare] = React.useState('');
    const [mesajEroareModificare, setMesajEroareModificare] = React.useState('');
    const [mesajEroareActivare, setMesajEroareActivare] = React.useState('');
    const [afiseazaButonActivare, setAfiseazaButonActivare] = React.useState(true);
    const [adaugaEroareFE, setAdaugaEroareFE] = React.useState(true);
    const [eroare,setEroare] = React.useState({"status":false});
    const [dateActivareFacturaElectronica, setDateActivareFacturaElectronica] = React.useState({});
    const [dateModificareFacturaElectronica, setDateModificareFacturaElectronica] = React.useState({});
    const [activatFactura, setActivatFactura] = React.useState(false);
    const [locuriConsumUtilizator,setLocuriConsumUtilizator] = React.useState([]);
    const [modificaFactura, setModificaFactura] = React.useState(false);
    const [afiseazaButonModificare, setAfiseazaButonModificare] = React.useState(true);
    const [emailPartener, setEmailPartener] = React.useState('');
    





    React.useEffect(() => {
        if (parametri.locuriConsumUtilizator.length>0 && partenerSelectat === ''){
          setPartenerSelectat(parametri.locuriConsumUtilizator[0].PoD);
        } 
      },[parametri.locuriConsumUtilizator]);
    
      let arataPartener = parametri.locuriConsumUtilizator.map(locConsum => (
        <MenuItem value={locConsum.PoD}>{locConsum.PoD+'-'+locConsum.Adresa}</MenuItem>
      ));

      const handleChangePartener = (event) => {
        setPartenerSelectat(event.target.value);
      };



        const handleOpenModal1 = () => setOpenModal1(true);
        const handleCloseModal1 = () => setOpenModal1(false);

        const handleOpenModal2 = () => setOpenModal2(true);
        const handleCloseModal2 = () => setOpenModal2(false);

        const handleChangeOpenEmail = (event) => {
          if(openEmail===false){
            setOpenEmail(true);
        }
        else if(openEmail===true) {
          setOpenEmail(false);
        }
        }
  
        const handleChangeEmail = (event) => {
          if(changeEmail===false){
            setChangeEmail(true);
        }
        else if(changeEmail===true) {
          setChangeEmail(false);
        }
        }


      //   const aduLocuriConsumCuAdresaDinLocalStorage= async function(){
      //     let locuriConsumUltimUpdate=JSON.parse(localStorage.getItem("locuriConsum"));
      //     if (!locuriConsumUltimUpdate){
      //       locuriConsumUltimUpdate={"locuriConsum":[]}
      //     }
      //     if ( locuriConsumUltimUpdate.locuriConsum.length !== locuriConsumUtilizator.length){
      //       setLocuriConsumUtilizator([...locuriConsumUltimUpdate.locuriConsum]); 
      //     }
          
      // }

      React.useEffect(() => {
    
       filtreazaFacturaElectronicaPerUtilizator();
       filtreazaValidarePE();
        
     },[partenerSelectat]);



      function filtreazaFacturaElectronicaPerUtilizator() {

        let dateFacturaElectronicaPartener = [];
        // const dataActivareValidare = "2023-06-12";
        const dataCurenta = new Date().toISOString().slice(0, 10);

        parametri.locuriConsumUtilizator.filter(dateFacturaElectronicaUtilizator =>{
          if(dateFacturaElectronicaUtilizator.PoD === partenerSelectat){
              dateFacturaElectronicaPartener.push(dateFacturaElectronicaUtilizator);
              setEmailPartener(dateFacturaElectronicaUtilizator.ZZFE_EMAIL);
          }
        })
        setInfoFacturaElectronicaPartener(dateFacturaElectronicaPartener);
      }

      function filtreazaValidarePE(){

        setModificaFactura(false);
        setAfiseazaButonModificare(true);
        setActivatFactura(false);
        setAfiseazaButonActivare(true);
      let validarePermisiuneFE = parametri.locuriConsumUtilizator.filter( dateFEPatener =>{
        const dataCurenta = new Date()
        if(dateFEPatener.PoD === partenerSelectat){
          let dataInceputRestictie = new Date(dateFEPatener.ZZFE_DATA_MODIFICARE);
          let dataSfarsitRestrictie = new Date(dataInceputRestictie.getTime() + 10 * 24 * 60 * 60 * 1000);
          if( dataInceputRestictie <= dataCurenta && dataSfarsitRestrictie >= dataCurenta){
            // daca nu are X la stare serviciu
            if(dateFEPatener.ZZFE_STARE_SERVICIU === ""){
            setActivatFactura(true);
            setAfiseazaButonActivare(false);
          }
            // daca are X la stare serviciu:
            if(dateFEPatener.ZZFE_STARE_SERVICIU === "X"){
            setModificaFactura(true);
            setAfiseazaButonModificare(false);
            }
          }
      }})
    }
      
      function validareEmailModificare () {
        
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;


        if(emailModificare !== emailConfirmareModificare){
          setMesajEroareModificare('Adresele de email nu corespund');
        }
        else if(emailRegex.test(emailModificare) === false && emailRegex.test(emailConfirmareModificare) === false){
          setMesajEroareModificare('Adresele de email nu sunt corecte');
        }
        else{
          setMesajEroareModificare('');
          handleOpenModal1();
          dateModificareFacturaElectronica.cont_contract = infoFacturaElectronicaPartener[0].cont_contract;
          dateModificareFacturaElectronica.ZZFE_STARE_SERVICIU = infoFacturaElectronicaPartener[0].ZZFE_STARE_SERVICIU;
          dateModificareFacturaElectronica.ZZFE_DATA_ACORD = infoFacturaElectronicaPartener[0].ZZFE_DATA_ACORD;
          dateModificareFacturaElectronica.ZZFE_DATA_DEZACTIV_ACORD = infoFacturaElectronicaPartener[0].ZZFE_DATA_DEZACTIV_ACORD;
          dateModificareFacturaElectronica.ZZFE_EMAIL = emailConfirmareModificare;
          dateModificareFacturaElectronica.ZZFE_DATA_MODIFICARE = new Date().toISOString();
          dateModificareFacturaElectronica.cod_partener = infoFacturaElectronicaPartener[0].Partener;
        }
      }

      function validareEmailActivare () {
        
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;


        if(emailActivare !== emailConfirmareActivare){
          setMesajEroareActivare('Adresele de email nu corespund');
        }
        else if(emailRegex.test(emailActivare) === false && emailRegex.test(emailConfirmareActivare) === false){
          setMesajEroareActivare('Adresele de email nu sunt corecte');
        }
        else{
          setMesajEroareActivare('');
          handleOpenModal2();
          dateActivareFacturaElectronica.cont_contract = infoFacturaElectronicaPartener[0].cont_contract;
          dateActivareFacturaElectronica.ZZFE_STARE_SERVICIU = "X";
          dateActivareFacturaElectronica.ZZFE_DATA_ACORD = infoFacturaElectronicaPartener[0].ZZFE_DATA_ACORD;
          dateActivareFacturaElectronica.ZZFE_DATA_DEZACTIV_ACORD = infoFacturaElectronicaPartener[0].ZZFE_DATA_DEZACTIV_ACORD;
          dateActivareFacturaElectronica.ZZFE_DATA_MODIFICARE = new Date().toISOString();
          dateActivareFacturaElectronica.cod_partener = infoFacturaElectronicaPartener[0].Partener;
          dateActivareFacturaElectronica.ZZFE_EMAIL = emailConfirmareActivare;

        }
      }

      
  //     function arataFacturaElectronica(){

  //       let aduFacturaElectronica=null;

        
  //       aduFacturaElectronica = infoFacturaElectronicaPartener.map(facturaElectronica => (

  //         <FormGroup style={{justifyContent: "center", alignItems: "center", margin: 'auto'}}>
  //         {facturaElectronica.ZZFE_STARE_SERVICIU === "X"?
  //         <div>
  //         <Typography style={{textAlign:'center', paddingTop:'2rem'}} id="textPerioadaTransmitere" variant="h6" component="h2">Factura electronica</Typography>
  //         <FormControlLabel control={<MySwitch onChange={handleChangeOpenEmail} />}  label="Activează factura electronică" />
  //         </div>
  //         :
  //         <div>
  //         <Typography style={{textAlign:'center', paddingTop:'2rem'}} id="textPerioadaTransmitere" variant="h6" component="h2">Factura printata</Typography>
  //         <Box style={{display: "flex", justifyContent: "center", alignItems: "center",paddingTop:"1rem"}}>
  //         <ColorButton  style={{display: "flex", justifyContent: "center", alignItems: "center", height: 35, color: '#FFFFFF'}} onClick={handleChangeEmail} >Schimbă adresa de email</ColorButton>
  //         </Box>
  //         </div>
  //         }

  //       {changeEmail? <div>
  //          <Box >
  //           <Box style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem"}}>
  //           <TextField
  //           required
  //           style={{textAlign: 'center',justifyContent: "center", alignItems: "center", display: "flex"}}
  //           id="emailFacturaElectronica"
  //           label="Email"
  //           />
  //           </Box>
  //           <Box style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem"}}>
  //           <TextField
  //           required
  //           style={{textAlign: 'center',justifyContent: "center", alignItems: "center", display: "flex"}}
  //           id="emailFacturaElectronica"
  //           label="Confirma email"
  //           />
  //           </Box>
  //           </Box>
  //         <Box style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem"}}>
  //         <ColorButton  style={{display: "flex", justifyContent: "center", alignItems: "center", height: 40, color: '#FFFFFF'}} onClick={handleOpenModal1} >Confirma</ColorButton>
  //         </Box>
  //         <Modal
  //       open={openModal1}
  //       onClose={handleCloseModal1}
  //       aria-labelledby="modal-modal-title"
  //       aria-describedby="modal-modal-description"
  //     >
  //       <Box sx={style}>
  //         <Typography id="modal-modal-title" variant="h6" component="h2">
  //           Adresa de email email@client.ro se modifica pentru contul de contract: {facturaElectronica.cont_contract} si este aplicata pentru luna urmatoare. Sunteti de acord?
  //         </Typography>
  //         <Box style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem"}}>
  //           <ColorButton  style={{display: "flex", justifyContent: "center", alignItems: "center", height: 40, color: '#FFFFFF'}} >Sunt de acord</ColorButton>
  //           </Box>
  //       </Box>
  //     </Modal>
  //       </div>: <Box style={{display: "flex", justifyContent: "center", alignItems: "center",paddingTop:"1rem"}}>
  //       <TextField
  //       required
  //       style={{textAlign: 'center',justifyContent: "center", alignItems: "center", display: "flex"}}
  //       id="emailFacturaElectronica"
  //       label="Adresa de email"
  //       defaultValue={facturaElectronica.ZZFE_EMAIL}
  //       disabled
  //       />
  //       </Box>}
  //       {openEmail?
  //       <div>
  //       <Box >
  //       <Box style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem"}}>
  //       <TextField
  //       required
  //       style={{textAlign: 'center',justifyContent: "center", alignItems: "center", display: "flex"}}
  //       id="emailFacturaElectronica"
  //       label="Email"
  //       />
  //       </Box>
  //       <Box style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem"}}>
  //       <TextField
  //       required
  //       style={{textAlign: 'center',justifyContent: "center", alignItems: "center", display: "flex"}}
  //       id="emailFacturaElectronica"
  //       label="Confirma email"
  //       />
  //       </Box>
  //       </Box>
  //       <Box style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem"}}>
  //       <ColorButton  style={{display: "flex", justifyContent: "center", alignItems: "center", height: 40, color: '#FFFFFF'}} onClick={handleOpenModal2} >Confirma</ColorButton>
  //       </Box>
  //       <Modal
  //   open={openModal2}
  //   onClose={handleCloseModal2}
  //   aria-labelledby="modal-modal-title"
  //   aria-describedby="modal-modal-description"
  // >
  //   <Box sx={style}>
  //     <Typography id="modal-modal-title" variant="h6" component="h2">
  //       Adresa de email email@client.ro se modifica pentru contul de contract: {facturaElectronica.cont_contract} si este aplicata pentru luna urmatoare. Sunteti de acord?
  //     </Typography>
  //     <Box style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem"}}>
  //       <ColorButton  style={{display: "flex", justifyContent: "center", alignItems: "center", height: 40, color: '#FFFFFF'}} >Sunt de acord</ColorButton>
  //       </Box>
  //   </Box>
  // </Modal>
  //     </div>:null}
  //         </FormGroup>
          
  //       ))
    
  //   setArataFacturaElectronicaPartener(aduFacturaElectronica);
  //   }

  const activareFacturaElectronica = async function () {

    let activeazaFacturaElectronica;

    try {
      setAfiseazaButonActivare(false);
      activeazaFacturaElectronica = await datePortal("activareFacturaElectronica",dateActivareFacturaElectronica,setEroare)
      handleCloseModal2();
      setOpenEmail(false);
      setActivatFactura(true);
      // call locuri de consum(de verificat daca se face de 2 ori ?)
      let locuriConsum= await  datePortal("aduLocuriConsumCuAdresa",null,setEroare);
        let locuriConsumUltimUpdate ={ dataUltimaActualizare: moment().format("YYYYMMDDhh"),
          "locuriConsum":locuriConsum
        }
        localStorage.removeItem('locuriConsum');
        localStorage.setItem("locuriConsum",JSON.stringify(locuriConsumUltimUpdate));
        setLocuriConsumUtilizator([...locuriConsumUltimUpdate.locuriConsum]); 
        await parametri.improspatareDateDupaStergere();
    } catch (error) {
      let eroare = JSON.parse(error.message);
      setEroare({"status":true,"mesaj":eroare.mesaj})
    }
    setAdaugaEroareFE(false);
    }

    const modificareFacturaElectronica = async function () {

      let modificaFacturaElectronica;
  
      try {
        setAfiseazaButonModificare(false);
        modificaFacturaElectronica = await datePortal("modificareFacturaElectronica",dateModificareFacturaElectronica,setEroare)
        handleCloseModal1();
        setChangeEmail(false);
        setModificaFactura(true);
        // call locuri de consum(de verificat daca se face de 2 ori ?)
        let locuriConsum= await  datePortal("aduLocuriConsumCuAdresa",null,setEroare);
        let locuriConsumUltimUpdate ={ dataUltimaActualizare: moment().format("YYYYMMDDhh"),
          "locuriConsum":locuriConsum
        }
        localStorage.setItem("locuriConsum",JSON.stringify(locuriConsumUltimUpdate));
        setLocuriConsumUtilizator([...locuriConsumUltimUpdate.locuriConsum]); 
        await parametri.improspatareDateDupaStergere();
      } catch (error) {
        let eroare = JSON.parse(error.message);
        setEroare({"status":true,"mesaj":eroare.mesaj})
      }
      setAdaugaEroareFE(false);
      }


    appInsights.trackPageView({name:'FacturaEletcronica'},properties)


    return(
        <div style={{ padding: '10rem', minWidth: '25rem', maxWidth: '50rem', margin: 'auto' }}>
            <Card>
      <CardContent>
      <Grid style={{
          justifyContent: "center", alignItems: "center", margin: 'auto'}}  xs={12} md={10} lg={8}>
          <FormControl fullWidth>
              <InputLabel id="alegePartener">Selectează locul de consum:</InputLabel>
                  <Select
                      labelId="alegePartener"
                      id="demo-simple-select"
                      value={partenerSelectat}
                      label="alegePartener"
                      onChange={handleChangePartener}
                  >
                    {arataPartener}
                  </Select>
          </FormControl>
          </Grid>
          {infoFacturaElectronicaPartener.map(facturaElectronica => (

            <FormGroup style={{justifyContent: "center", alignItems: "center", margin: 'auto'}}>

            {facturaElectronica.ZZFE_STARE_SERVICIU === "X"?
            <div>
            <Typography style={{textAlign:'center', paddingTop:'2rem'}} id="textPerioadaTransmitere" variant="h6" component="h2">Factura electronica</Typography>
            {modificaFactura?
            <div>
            <Box style={{display: "flex", justifyContent: "center", alignItems: "center",paddingTop:"1rem"}}>
            <ColorButtonDisable disabled style={{display: "flex", justifyContent: "center", alignItems: "center", height: 35, color: '#FFFFFF'}} onClick={handleChangeEmail} >Schimbă adresa de email</ColorButtonDisable>
            </Box>
            <Box style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem"}}>
            <Typography id="modal-modal-title" variant="subtitle1" component="h5">
            &nbsp;&nbsp;&nbsp;&nbsp;Solicitarea ta este în curs de procesare.
            </Typography>
            </Box>
            </div>
            :<Box style={{display: "flex", justifyContent: "center", alignItems: "center",paddingTop:"1rem"}}>
            <ColorButton  style={{display: "flex", justifyContent: "center", alignItems: "center", height: 35, color: '#FFFFFF'}} onClick={handleChangeEmail} >Schimbă adresa de email</ColorButton>
            </Box>}
            </div>
            :
            <div>
            <Typography style={{textAlign:'center', paddingTop:'2rem'}} id="textPerioadaTransmitere" variant="h6" component="h2">În prezent primești factura în format fizic</Typography>
            {activatFactura?
            <div>
            <FormControlLabel control={<MySwitch defaultChecked disabled onChange={handleChangeOpenEmail} />}  label="Activează acum factura electronică" />
            <Box style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem"}}>
            <Typography id="modal-modal-title" variant="subtitle1" component="h5">
            &nbsp;&nbsp;&nbsp;&nbsp;Solicitarea ta este în curs de procesare.
            </Typography>
            </Box>
            </div>
            :
            <FormControlLabel control={<MySwitch onChange={handleChangeOpenEmail} />}  label="Activează acum factura electronică" />}
            </div>
            }
            {(() => {
            if(changeEmail&&facturaElectronica.ZZFE_STARE_SERVICIU === "X"){
            return(
              <div>
            <Box >
              <Box style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem"}}>
              <TextField
              required
              style={{textAlign: 'center',justifyContent: "center", alignItems: "center", display: "flex"}}
              id="emailFacturaElectronica"
              label="Email"
              onChange={ (e) => setEmailModificare(e.target.value)}
              error = { mesajEroareModificare !== '' ? true : false }
               helperText = {mesajEroareModificare !== '' ? mesajEroareModificare:null}
              />
              </Box>
              <Box style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem"}}>
              <TextField
              required
              style={{textAlign: 'center',justifyContent: "center", alignItems: "center", display: "flex"}}
              id="emailFacturaElectronica"
              label="Confirma email"
              onChange={ (e) => setEmailConfirmareModificare(e.target.value)}
               error = { mesajEroareModificare !== '' ? true : false }
               helperText = {mesajEroareModificare !== '' ? mesajEroareModificare:null}
              />
              </Box>
              </Box>
            <Box style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem"}}>
            <ColorButton  style={{display: "flex", justifyContent: "center", alignItems: "center", height: 40, color: '#FFFFFF'}} onClick={()=>{validareEmailModificare();}} >Confirma</ColorButton>
            </Box>
            <Modal
            open={openModal1}
            onClose={handleCloseModal1}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
            <IconButton style={{ color: '#000000', position: 'absolute', right:'1vw', top:'1px' }} color="primary" aria-label="add to shopping cart">
              <CloseIcon onClick={handleCloseModal1} />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
            &nbsp;&nbsp;&nbsp;&nbsp;Începând cu următoarea perioadă de facturare, adresa ta de e-mail {emailConfirmareModificare} va fi actualizată. 
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h2">
            &nbsp;&nbsp;&nbsp;&nbsp;Te rugăm să confirmi că dorești această schimbare.
            </Typography>
            <Box style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem"}}>
              {afiseazaButonModificare?<ColorButton  style={{display: "flex", justifyContent: "center", alignItems: "center", height: 40, color: '#FFFFFF'}} onClick={modificareFacturaElectronica}>Confirmă</ColorButton>:null}
              </Box>
            </Box>
            </Modal>
            </div>
            )}
            else if(!changeEmail&&facturaElectronica.ZZFE_STARE_SERVICIU === "X"){
              return(
            <Box style={{display: "flex", justifyContent: "center", alignItems: "center",paddingTop:"1rem"}}>
            <TextField
            required
            style={{textAlign: 'center',justifyContent: "center", alignItems: "center", display: "flex"}}
            id="emailFacturaElectronica"
            label="Adresa de email"
            key={facturaElectronica.ZZFE_EMAIL}
            defaultValue={facturaElectronica.ZZFE_EMAIL}
            disabled
            />
            </Box>
            )}
            })()}
            {openEmail&&facturaElectronica.ZZFE_STARE_SERVICIU === ""?
            <div>
            <Box >
            <Box style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem"}}>
            <TextField
            required
            style={{textAlign: 'center',justifyContent: "center", alignItems: "center", display: "flex"}}
            id="emailFacturaElectronica"
            label="Email"
            onChange={ (e) => setEmailActivare(e.target.value)}
            error = { mesajEroareActivare !== '' ? true : false }
            helperText = {mesajEroareActivare !== '' ? mesajEroareActivare:null}
            />
            </Box>
            <Box style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem"}}>
            <TextField
            required
            style={{textAlign: 'center',justifyContent: "center", alignItems: "center", display: "flex"}}
            id="emailFacturaElectronica"
            label="Confirma email"
            onChange={ (e) => setEmailConfirmareActivare(e.target.value)}
               error = { mesajEroareActivare !== '' ? true : false }
               helperText = {mesajEroareActivare !== '' ? mesajEroareActivare:null}
            />
            </Box>
            </Box>
            <Box style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem"}}>
            {afiseazaButonActivare?<ColorButton  style={{display: "flex", justifyContent: "center", alignItems: "center", height: 40, color: '#FFFFFF'}} onClick={()=>{validareEmailActivare();}} >Confirma</ColorButton>:null}
            </Box>
            <Modal
            open={openModal2}
            onClose={handleCloseModal2}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            >
            <Box sx={style}>
            <IconButton style={{ color: '#000000', position: 'absolute', right:'1vw', top:'1px' }} color="primary" aria-label="add to shopping cart">
              <CloseIcon onClick={handleCloseModal2} />
            </IconButton>
            <Typography id="modal-modal-title" variant="h6" component="h2">
            &nbsp;&nbsp;&nbsp;&nbsp;Începând cu următoarea perioadă de facturare, vei primi factura/facturile aferente contului tău de contract pe adresa de e-mail pe care ai introdus-o.
            </Typography>
            <Typography id="modal-modal-title" variant="h6" component="h2">
            &nbsp;&nbsp;&nbsp;&nbsp;Dacă pe contul de contract {facturaElectronica.cont_contract} sunt atașate mai multe locuri de consum, vei primi toate facturile aferente acestora pe adresa de e-mail introdusă de tine {emailConfirmareActivare}.
            </Typography>
            <Box style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "2rem"}}>
            <ColorButton  style={{display: "flex", justifyContent: "center", alignItems: "center", height: 40, color: '#FFFFFF'}} onClick={activareFacturaElectronica}>Activează</ColorButton>
            </Box>
            </Box>
            </Modal>
            </div>:null}
            {facturaElectronica.ZZFE_STARE_SERVICIU === "X"?
            <Box style={{display: "flex", justifyContent: "center", alignItems: "center", paddingTop: "4rem"}}>
            <Typography id="modal-modal-title" variant="subtitle1" component="h5">
            &nbsp;&nbsp;&nbsp;&nbsp;Dacă vrei să dezactivezi factura electronică, intră pe site-ul nostru și accesează secțiunea <a href="https://www.premierenergy.ro/contact">contact</a>
            </Typography>
            </Box>
            :null}
            </FormGroup>
            ))}
          </CardContent>  
      </Card>
        </div>
    )
}
export default withAppInsights(FacturaElectronica);