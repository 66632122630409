import './App.css';
import * as React from 'react';
import cerereDeRacordare from './cerereDeRacordare/cerereDeRacordare';
import ContulMeu from './contulMeu/contulMeu';
import contact from './contact/contact';
import Home from './home/home'
import { Header } from './header/Header';
import { datePortal } from './api/api';
import SoldRapid from './soldRapid/soldRapid'
import moment from "moment";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import { esteAutentificat } from './security/sec';
import IndexConsum from './indexConsum/indexConsum';
import TabelButoaneFacturiSiPlati from './facturiPlati/facturiPlati';
import {redirectToLogin,userDinToken } from './security/sec';
import {appInsights} from './AppInsights';
import modalEroare from './testModal/modalEroare'
import { LinearProgress, Grid } from '@material-ui/core';
import { AratafacturiPartener } from './facturiPlati/FacturiEmise';
import TransmitereIndex from './transmitereIndex/transmitereIndex';
import Typography from '@material-ui/core/Typography';
import FacturaElectronica from './facturaElectronica/infoFacturaElectronica';



function App() {
  const [esteInRadacina, setEsteInRadacina] = React.useState(true);
  const [locuriConsumUtilizator,setLocuriConsumUtilizator] = React.useState([]);
  const [facturiClient, setFacturiClient] = React.useState([]);
  const [eroare,setEroare] = React.useState({"status":false});
  const [retryFacturi,setRetryFacturi] = React.useState(1);
  const [retryLocuriDeConsum,setRetryLocuriDeConsum] = React.useState(1);
  const [nuAreLocuriDeConsum,setNuAreLocuriDeConsum] = React.useState(false);
  const [dateCitite,setDateCitite] = React.useState(false);
  
  
  const [autentificat, setAutentificat] = React.useState(esteAutentificat());
  
  const aduLocuriConsumCuAdresaDinLocalStorage= async function(){
    await  aduLocuriConsumCuAdresaApi();  
    let locuriConsumUltimUpdate=JSON.parse(localStorage.getItem("locuriConsum"));
      if (!locuriConsumUltimUpdate){
        locuriConsumUltimUpdate={"locuriConsum":[]}
      }
      if (!locuriConsumUltimUpdate.locuriConsum){
        locuriConsumUltimUpdate={"locuriConsum":[]}
      }
      if ( JSON.stringify(locuriConsumUltimUpdate.locuriConsum) !== JSON.stringify(locuriConsumUtilizator)){
        setLocuriConsumUtilizator([...locuriConsumUltimUpdate.locuriConsum]); 
      }
      if (locuriConsumUltimUpdate.locuriConsum.length<1){
        setNuAreLocuriDeConsum(true);
      }
      else {
       setNuAreLocuriDeConsum(false);
      }      
  }

  const aduLocuriConsumCuAdresaApi= async function(){
    let INTERVAL_REFRESH_LOCURI_CONSUM_ORE= parseInt(process.env.REACT_APP_INTERVAL_REFRESH_LOCURI_CONSUM_ORE);
    let locuriConsumUltimUpdate=JSON.parse(localStorage.getItem("locuriConsum"));
    let refresh=true
    
      if (locuriConsumUltimUpdate && locuriConsumUltimUpdate.dataUltimaActualizare){
        let oraActuala=parseInt(moment().format("YYYYMMDDhh")) 
        let dataUltimaActualizare=parseInt(locuriConsumUltimUpdate.dataUltimaActualizare)
        if (oraActuala-dataUltimaActualizare<INTERVAL_REFRESH_LOCURI_CONSUM_ORE){
          if (locuriConsumUltimUpdate.locuriConsum.length===0){
            setNuAreLocuriDeConsum(true);
          }
          else {
            setNuAreLocuriDeConsum(false);
          }
          refresh=false
        }
        else{
          setRetryLocuriDeConsum(1);
        }
    }
    else if (retryLocuriDeConsum>5){
      refresh=false
      }

    if (refresh){
      
      try {
        let locuriConsum= await  datePortal("aduLocuriConsumCuAdresa",null,setEroare);
        let locuriConsumUltimUpdate ={ dataUltimaActualizare: moment().format("YYYYMMDDhh"),
          "locuriConsum":locuriConsum
        }
         localStorage.setItem("locuriConsum",JSON.stringify(locuriConsumUltimUpdate));
        setLocuriConsumUtilizator([...locuriConsumUltimUpdate.locuriConsum]); 
        refresh=false;
        if (locuriConsumUltimUpdate.locuriConsum.length===0){
          setNuAreLocuriDeConsum(true);
        }
        else {
          setNuAreLocuriDeConsum(false);
        }
        
      } catch (err) {
        let eroare = JSON.parse(err.message);
        let properties={
          userId :userDinToken(),
          page:"APP",
          functie:"aduLocuriConsumCuAdresaApi"
        };
        appInsights.trackException({error: err, properties }); // pt Ramona de pus unde trebuie 
        if (eroare.mesaj ==='jwt not active'){
          await new Promise(resolve => setTimeout(resolve, 1000));
       }
         setRetryLocuriDeConsum(retryLocuriDeConsum+1);
      }
    
        

    }
    
  }

    
  const adaugaFacturiApi = async function () {

    
    let INTERVAL_REFRESH_LOCURI_CONSUM_ORE= parseInt(process.env.REACT_APP_INTERVAL_REFRESH_LOCURI_CONSUM_ORE);
    let facturiUtilizatorUltimUpdate=JSON.parse(localStorage.getItem("FacturiUtilizator"));
    let refresh=true
    if (facturiUtilizatorUltimUpdate && facturiUtilizatorUltimUpdate.dataUltimaActualizare){
        let oraActuala=parseInt(moment().format("YYYYMMDDhh")) 
        let dataUltimaActualizare=parseInt(facturiUtilizatorUltimUpdate.dataUltimaActualizare)
        if (oraActuala-dataUltimaActualizare<INTERVAL_REFRESH_LOCURI_CONSUM_ORE){
        refresh=false
        }
        else{
          setRetryFacturi(1);
        }

    }
    else if(retryFacturi>5){
      refresh=false;
    }    
    if (refresh){
          try {
                let facturiUtilizator= await datePortal("aducFacturile",null,setEroare)
                let facturiGrupate = [];
      
                facturiUtilizator.forEach(
      
                facturaLocal => {
      
                  let listaFacturiPartener=facturiGrupate.filter(factura => factura.codPartener===facturaLocal.PARTNER)
      
                  if (listaFacturiPartener.length < 1 ){
      
                    facturiGrupate.push(
                      {
                      "codPartener":facturaLocal.PARTNER,
                      "facturi":[]
                      }
                      )
                    };
      
                      let partenerCurent=facturiGrupate.filter(factura => factura.codPartener===facturaLocal.PARTNER)[0];
                      partenerCurent.facturi.push(
                                {
                                  "numarFactura":facturaLocal.OPBEL,
                                  "dataScadenta":facturaLocal.FAEDN,
                                  "dataEmiterii":facturaLocal.BLDAT,
                                  "totalDePlata":facturaLocal.TOTAL_AMNT,
                                  "contContract":facturaLocal.VKONT,
                                  "contractFurnizare":facturaLocal.vertrag,
                                  "PoD":facturaLocal.POD,
                                  "existaPDF":facturaLocal.existaPDF
                                }
                            )
                })
      
                let facturiPartenerUltimUpdate ={ dataUltimaActualizare: moment().format("YYYYMMDDhh"),
                    "Facturi":facturiGrupate
                  }
                  localStorage.setItem("FacturiUtilizator",JSON.stringify(facturiPartenerUltimUpdate))       
  
            
          } catch (error) {
            eroare = JSON.parse(error.message);
            if (eroare.mesaj ==='jwt not active'){
              await new Promise(resolve => setTimeout(resolve, 1000));
             }
            setRetryFacturi(retryFacturi+1);            
          }


        }

    };
  
    const adaugaFacturiDinLocalStorage= async function(){
      await  adaugaFacturiApi();
      let facturiPartenerUltimUpdate=JSON.parse(localStorage.getItem("FacturiUtilizator"));
      if (!facturiPartenerUltimUpdate){
        facturiPartenerUltimUpdate={"Facturi":[]}
      }
      if ( JSON.stringify(facturiPartenerUltimUpdate.Facturi) !== JSON.stringify(facturiClient)){
        setFacturiClient([...facturiPartenerUltimUpdate.Facturi]); 
      }
      if (!dateCitite){
        setDateCitite(true);
      }
  }


  const aduDate = async () => {
    if (!autentificat){
      redirectToLogin();
    }

    if (autentificat) {
      await aduLocuriConsumCuAdresaDinLocalStorage();   
      await adaugaFacturiDinLocalStorage();
    }
  };
    

  const runAduDateParent =  async () =>{
    await aduDate()    ;
    let amCitit =  localStorage.getItem("FacturiUtilizator");
    if (amCitit)
     {
       setDateCitite(true);
     }
    }
  
  // React.useEffect(() => {
  //   runAduDateParent();
  // });

  React.useEffect(() => {
    
    setRetryFacturi(1);
    setRetryLocuriDeConsum(1);
    runAduDateParent();
    
  },[]);


  React.useEffect(() => {
    if(retryLocuriDeConsum!=1){
    runAduDateParent();
  }
  },[retryLocuriDeConsum]);

  // React.useEffect(() => {
    
  //   let amCitit =  localStorage.getItem("FacturiUtilizator");
  //   if (amCitit)
  //    {
  //      setDateCitite(true);
  //    }
  // });


    return (       
      <Router>
      <div>
      <Header 
      esteInRadacina ={esteInRadacina} 
      setEsteInRadacina={setEsteInRadacina}
      autentificat={autentificat}
      
       />
       {dateCitite  &&
        <div style={autentificat ? {}:{pointerEvents: "none", opacity: "0.4"}}>
        <Switch>
          <Route exact path="/">
            <Home locuriConsumUtilizator={locuriConsumUtilizator}  
              nuAreLocuriDeConsum ={nuAreLocuriDeConsum}
              setNuAreLocuriDeConsum={setNuAreLocuriDeConsum}
              retryLocuriDeConsum={retryLocuriDeConsum}
              />
            </Route>
          <Route path="/index-consum">
          <IndexConsum
            autentificat={autentificat}
            locuriConsumUtilizator={locuriConsumUtilizator}
          />
          </Route>
          <Route path="/sold-rapid">
          <SoldRapid locuriConsumUtilizator={locuriConsumUtilizator}/>
          </Route>
          <Route path="/facturi-plati"> 
              <AratafacturiPartener facturiClient={facturiClient} locuriConsumUtilizator={locuriConsumUtilizator}/>
          </Route> 
          <Route path="/transmitere-index">
              <TransmitereIndex  locuriConsumUtilizator={locuriConsumUtilizator}/>
          </Route>
          <Route path="/locuri-de-consum" >
              <ContulMeu locuriConsumUtilizator={locuriConsumUtilizator} improspatareDateDupaStergere={aduDate}/>
          </Route>
          <Route path="/factura-electronica" >
              <FacturaElectronica locuriConsumUtilizator={locuriConsumUtilizator} improspatareDateDupaStergere={aduDate}/>
          </Route>
          <Route path="/contact" component={contact}/>
          <Route path="/login" component={contact}/>
          <Route path="/modalEroare" component={modalEroare}/>
        </Switch>
        </div>}
        {!dateCitite  && retryLocuriDeConsum<=5 ?
          // <img src={loaderPremier} alt="loading..." style={{margin: '0', position: 'absolute', top: '50%', left: '48%', width: '40px'}}/>
            <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} md={12} lg={12}>
              <LinearProgress style={{position:'absolute', width: '50%', top: '50%', left: '25%', margin:'auto', color:"#1e456d"}} />
              </Grid>
            </Grid> : null
          }
          {retryLocuriDeConsum>5 ? <Grid container justifyContent="center" alignItems="center">
              <Grid item xs={12} md={12} lg={12}>
              <Typography style={{position:'absolute', width: '50%', top: '50%', left: '25%', margin:'auto', color:"#1e456d"}} variant="h5" component="h5">
              Ne pare rău, avem dificultăți cu verificarea contului, te rugăm să încerci mai tărziu. Pentru informații ne poți contacta la telefon 021 231 10 21 sau prin intermediul formularului nostru de <a href="https://www.premierenergy.ro/contact">contact</a>. Mulțumim pentru înțelegere!
              </Typography>
              </Grid>
            </Grid> : null}
      </div>
    </Router>
    );
}

export default App;