import { TextField, InputAdornment, IconButton, Button, Modal, Typography, Box, CircularProgress } from '@material-ui/core';
import React from 'react';
import CloseIcon from '@material-ui/icons/Close';
import { datePortal } from '../api/api';


  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    bgcolor: 'background.paper',
    border: '2px solid #FF9241',
    boxShadow: 24,
    pt: 2,
    px: 4,
    pb: 3,
  
  };

  function PlatesteSold(parametri){

    const [sumaDePlata,setSumaDePlata] = React.useState(parametri.sumaDePlata);

      return (
          <div>
              <p style={{}}><b>Contract: {parametri.contract}</b></p>
              <br/>
              <TextField 
                onChange={(el) => {setSumaDePlata(el.currentTarget.value)}}
                id="sumaPlata" 
                label="Suma" 
                variant="outlined"
                sx={{borderColor:"#FF9241"}}
                defaultValue={sumaDePlata}
                InputProps={{
                endAdornment: <InputAdornment position="end">RON</InputAdornment>,
                 }}
              />
              <br/>
              <div style={{textAlign: 'right', border: '20px', paddingTop: '20px'}}>
               {<Button variant="contained" style={{backgroundColor: '#FF9241',color:"#FFFFFF"}} key='111' onClick={()=>{parametri.valideazaPlata(sumaDePlata,parametri.contract)}}> Plateste</Button>}
          </div>
          </div>
  
      )
  
  }

function PlatesteFactura(parametri) {

    const [sumaPlata, setSumaPlata] = React.useState(parametri.sumaDePlata);
    const [afiseazaButonPlata,setAfiseazaButonPlata]=React.useState(true);
    const [eroare,setEroare] = React.useState({"status":false});
    const [open, setOpen] = React.useState(parametri.arataModalPlata);
    const [afiseazaLoaderSave,setAfiseazaLoaderSave]=React.useState(false);


    const handleClose = () => {
      setOpen(false);
      parametri.setArataModalPlata(false)
    };



    function valideazaPlata(sumaDePlata,contract){


       let contractFurnizare =contract.slice(4,10)


        let cheie = 0;
        let i = 0;
        let hashTable = [3, 1, 3, 1, 3, 1];
        for(i = 0; i < 6; i++){
         let cifra = parseInt(contractFurnizare.charAt(i))
          cheie = cheie+ cifra * hashTable[i]

        }

        cheie = cheie % 10
        let codAutocitire = "8"+contractFurnizare+cheie;

        let sumadePlataCorecta = Number(sumaDePlata).toFixed(2); 

        let datePlata = {"cod":codAutocitire,
        "suma":sumadePlataCorecta}


      handleClose();
      setAfiseazaButonPlata(true);
      setAfiseazaLoaderSave(true);

       const getLinkEuPlatesc =async () => {
          let linkEuPlatesc = await datePortal("redirectEuPlatesc",datePlata,setEroare);
          window.location.replace(linkEuPlatesc);
      };
     getLinkEuPlatesc()
       
       
       
                //.post('http://localhost:7071/api/RedirectEuPlatesc',oDataJSON) // pentru test local
            // /.then(response => {
            //   if(response.status){
            //     console.error(response)
            //     let info=document.getElementById("info");
            //   }
            //   else{
            //     window.location.replace(response.data);
            //   }
                
            // })
            // .catch(error => {
            //     console.error(error)
            //     let info=document.getElementById("info");
              
            //     // info.textContent=" A apărut o eroare în redicționarea către procesatorul de plăți. Vă rugăm să mai încercați odată. Dacă problema persistă vă rugăm luați legătura cu departamentul de relații cu publicul pentru a semnala problema dumneavoastră. "

            // }
            //     );

}
    
  


      
      
    



return(
  <div>
    <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{ ...style }}>
        <IconButton style={{ color: '#000000', position: 'absolute', right:'1vw', top:'1px' }} color="primary" aria-label="add to shopping cart">
          <CloseIcon onClick={handleClose} />
        </IconButton>
          <Typography style={{textAlign:'center'}} id="parent-modal-title" variant="h6" component="h2">Puteti sa modificati suma pe care doriti sa o achitati</Typography>
          <br/>
          <p style={{textAlign: 'center'}} id="parent-modal-description">
            <PlatesteSold
             sumaDePlata={parametri.soldPartenerSelectat.sold}
             contract={parametri.soldPartenerSelectat.contractFurnizare}
             valideazaPlata={valideazaPlata}
            />
          </p>
          <br/>
          {afiseazaLoaderSave && <CircularProgress />}
          {eroare.status && <h2> {eroare.mesaj} </h2>}
        </Box>

      </Modal>
    </div>
)

}

export default PlatesteFactura;