import React from 'react';
import { Box, Select, Grid, TextField, Tooltip, Typography, InputLabel, FormControl, MenuItem,  Button, CardContent, Card, CardActions } from '@material-ui/core';
import { datePortal } from '../api/api';
import infoIndex from '../images/infoIndex.jpeg';
import withAppInsights from '../AppInsights';
import {appInsights} from '../AppInsights';
import {userDinToken} from '../security/sec'



function TransmitereIndex(parametri) {

  const properties = {userId : userDinToken()}
  const [partenerSelectat, setPartenerSelectat] = React.useState(parametri.locuriConsumUtilizator[0]?parametri.locuriConsumUtilizator[0].PoD:'');
  const [perioadaInceputIndex, setPerioadaInceputIndex] = React.useState([]);
  const [perioadaSfarsitIndex, setPerioadaSfarsitIndex] = React.useState([]);
  const [poateTransmiteIndexul, setPoateTransmiteIndexul] = React.useState(false);
  const [locConsum, setLocConsum] = React.useState([]);
  const [eroare,setEroare] = React.useState({"status":false});
  const [adaugaIndexLocConsum, setAdaugaIndexLocConsum] = React.useState({});
  const [adaugaEroareIndex,setAdaugaEroareIndex]=React.useState(false);
  const [pozitieIndex, setPozitieIndex] = React.useState([]);
  const [afiseazaPozitieIndex, setAfiseazaPozitieIndex] = React.useState(false);
  // const [indexIntrodus, setIndexIntrodus] = React.useState([]);
  const [dataInceputTransmitereIndex, setDataInceputTransmitereIndex] = React.useState([]);
  const [dataSfarsitTransmitereIndex, setDataSfarsitTransmitereIndex] = React.useState([]);
  const [contract, setContract] = React.useState([]);

  // let indexIntrodus;

  appInsights.trackPageView({name:'TransmitereIndex'},properties)

  function handleOnChange (tip,valoare) {
    if (tip==="index"){
      adaugaIndexLocConsum.index=valoare;
      adaugaIndexLocConsum.locConsum = locConsum;
      adaugaIndexLocConsum.sursa = sursa;
      adaugaIndexLocConsum.contract = contract;
    }
      
    setAdaugaIndexLocConsum(adaugaIndexLocConsum);
  }

  React.useEffect(() => {
    
    filtreazaPerioadaAutocitirePerUtilizator();
    setAfiseazaPozitieIndex(false);
    
    

 },[partenerSelectat]);


  function filtreazaPerioadaAutocitirePerUtilizator() {

    let dataAstazi = Date.now();
    let date_ob = new Date(dataAstazi);
    let ziuaDeAstazi = date_ob.getDate();
    // let ziuaDeAstazi = "25";

    // let testDataSfarsit = 29;
    
    let perioadaAutocitirePerUtilizator = parametri.locuriConsumUtilizator.filter(dateUtilizator =>{
      if(dateUtilizator.PoD === partenerSelectat) {
        // let perioadaCitireInceputLocal = dateUtilizator.perioada_citire_start;
        // let perioadaCitireSfarsitLocal = dateUtilizator.perioada_citire_sfarsit;

        // let ziCitireInceputTranformata = perioadaCitireInceputLocal.slice(8,10);
        // let ziCitireSfarsitTranformata = perioadaCitireSfarsitLocal.slice(8,10);
        // let lunaInceputTransmitereIndex = perioadaCitireInceputLocal.slice(5,7);
        // let lunaSfarsitTransmitereIndex = perioadaCitireInceputLocal.slice(5,7);
        // let anInceputTransmitereIndex = perioadaCitireInceputLocal.slice(0,4);
        // let anSfarsitTransmitereIndex = perioadaCitireInceputLocal.slice(0,4);
        // let dataIntreagaInceputTransmitere = ziCitireInceputTranformata + "." + lunaInceputTransmitereIndex + "." + anInceputTransmitereIndex;
        // let dataIntreagaSfarsitTransmitere = ziCitireSfarsitTranformata + "." + lunaSfarsitTransmitereIndex + "." + anSfarsitTransmitereIndex;

        // setDataInceputTransmitereIndex(dataIntreagaInceputTransmitere);
        // setDataSfarsitTransmitereIndex(dataIntreagaSfarsitTransmitere);
        setPoateTransmiteIndexul(true);
        // if(ziCitireInceputTranformata <= ziuaDeAstazi && ziCitireSfarsitTranformata >= ziuaDeAstazi) {
        //   setPoateTransmiteIndexul(true);
        // }
        if(dateUtilizator.CONTR_DIST.length > 4 && dateUtilizator.CONTR_FURN.length > 4){
          setContract(dateUtilizator.CONTR_FURN)
        }
        if(dateUtilizator.CONTR_DIST.length < 4){
          setContract(dateUtilizator.CONTR_FURN)
        }
        if(dateUtilizator.CONTR_FURN.length < 4){
          setContract(dateUtilizator.CONTR_DIST)
        }
        setLocConsum(dateUtilizator.PoD);
        // setPerioadaInceputIndex(ziCitireInceputTranformata);
        // setPerioadaSfarsitIndex(ziCitireSfarsitTranformata);
        
      } 
    })
}

    let sursa = "portalClienti";

    const trimiteIndex = async function (){

      setAdaugaEroareIndex(false);

      if (!adaugaIndexLocConsum.index) {
        setAdaugaEroareIndex(true);
      }
      else {
        let indexLocConsum;
      try {
        
        indexLocConsum = await datePortal("transmitereIndex", adaugaIndexLocConsum, setEroare);

        setPozitieIndex(indexLocConsum);
        setAfiseazaPozitieIndex(true);
        // indexIntrodus.target.reset();
        // document.getElementById('inputIndex').value = '';
        

      } catch (error) {
        
        let eroare = JSON.parse(error.message);
        setEroare(eroare)
        
      }}};




  const handleChangePartener = (event) => {
    setPartenerSelectat(event.target.value);
  };

  React.useEffect(() => {
    if (parametri.locuriConsumUtilizator.length>0 && partenerSelectat === ''){
      setPartenerSelectat(parametri.locuriConsumUtilizator[0].PoD); 
    }
      
  },[parametri.locuriConsumUtilizator]);

  let arataPartener = parametri.locuriConsumUtilizator.map(locConsum => (
    <MenuItem value={locConsum.PoD}>{locConsum.PoD+'-'+locConsum.Adresa}</MenuItem>
  ));
 
  return (
    <div style={{ padding: '10rem', minWidth: '25rem', maxWidth: '50rem', margin: 'auto' }}>
      <Card>
      <CardContent>
      <Grid style={{
          justifyContent: "center", alignItems: "center", margin: 'auto'}}  xs={12} md={10} lg={8}>
          <FormControl fullWidth>
              <InputLabel id="alegePartener">Alegeți locul de consum:</InputLabel>
                  <Select
                      labelId="alegePartener"
                      id="demo-simple-select"
                      value={partenerSelectat}
                      label="alegePartener"
                      onChange={handleChangePartener}
                  >
                    {arataPartener}
                  </Select>
          </FormControl>
          {afiseazaPozitieIndex ? <Typography style={{textAlign:'center', paddingTop:'2rem'}} id="parent-modal-title" variant="h6" component="h2">
      {pozitieIndex}
      </Typography>
      :
      null}
        {poateTransmiteIndexul && !afiseazaPozitieIndex ? 
        <div><Tooltip title={
          <>
            <img src={infoIndex} />
          </>
        }>
          <p style={{textAlign: 'center', justifyContent: "center"}}>Vă rugăm să introduceți indexul afișat pe contor</p>
          </Tooltip>
        <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: "center", alignItems: "center"
        }}>
        <TextField
        inputProps={{inputMode: 'numeric', pattern: '/^(?:[1-9]\d*|\d)$/', min: 0, style: { textAlign: 'center' }}}
        required
        helperText={ adaugaEroareIndex ? "Completați campul acesta pentru validare." : null}
        error = { adaugaEroareIndex ? true : false }
        onKeyDown={(e) => {
          if (e.key === "e" || e.key === "E" || e.key === "-" || e.key === "+" || e.key === ".") {
            e.preventDefault()
          }
        }}
        // style={{textAlign: 'center', width: '23 rem'}}
          // value={indexIntrodus}
          type="number"
        id="inputIndex"
        onChange={ (e)=> handleOnChange("index",e.target.value)}
        />
        </Box></div>
        : 
        null}
      </Grid>
      </CardContent>
      {poateTransmiteIndexul && !afiseazaPozitieIndex ?
      <CardActions>
      <Button style={{backgroundColor: '#FF9241',color:"#FFFFFF", justifyContent: "center", alignItems: "center", margin: 'auto'}} onClick={trimiteIndex}> Trimite</Button>
      </CardActions>
      : <CardActions></CardActions>}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: "center", alignItems: "center"
        }}>
      <Typography style={{textAlign:'center', paddingTop:'2rem'}} id="textEroareIndex" variant="subtitle1" component="subtitle1"><b>{eroare.mesaj}</b></Typography>
      </Box>
      </Card>
    </div>
  );
}
 
export default withAppInsights(TransmitereIndex);