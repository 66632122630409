import { InputLabel, Table, Button, TableBody, MenuItem, TableCell, TableContainer, TableHead, TableRow, Box, Select, FormControl } from '@material-ui/core';
import React from 'react';
import { datePortal } from '../api/api'; 
import moment from 'moment';
import { styled } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import PlatesteFactura from './platesteFactura';
import withAppInsights from '../AppInsights';
import {appInsights} from '../AppInsights';
import {userDinToken} from '../security/sec'

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#FF9241'),
  borderRadius: '',
  backgroundColor: '#FF9241',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#ffaf74',
  },
  '&:focus': {
    backgroundColor: '#ffaf74',
    textColor: '#FFFFFF',

  },
}));


function SoldRapid(parametri) {
 
  const properties = {userId : userDinToken()}
  const [arataSoldPartener, setArataSoldPartener] = React.useState(null);
  const[soldUtilizator,setSoldUtilizator] = React.useState([{"locuriConsum":[]}]);
  const[soldPartenerSelectat,setSoldPartenerSelectat] = React.useState([{"sold":[]}]);
  const [eroare,setEroare] = React.useState({"status":false});
  const [partenerSelectat, setPartenerSelectat] = React.useState(parametri.locuriConsumUtilizator[0]?parametri.locuriConsumUtilizator[0].PoD:'');
  const [soldIncarcat, setSoldIncarcat] = React.useState(false);
  const [arataModalPlata, setArataModalPlata] = React.useState(false);
  const [indexFacturaDePlata,setIndexFacturaDePlata] = React.useState(-1);
  const [retrySold, setRetrySold] = React.useState(1);

  appInsights.trackPageView({name:'SoldRapid'},properties)

  const aducSoldPeUtilizatorApi = async function () {

    let INTERVAL_REFRESH_LOCURI_CONSUM_ORE= parseInt(process.env.REACT_APP_INTERVAL_REFRESH_LOCURI_CONSUM_ORE);
    let soldUtilizatorUltimUpdate=JSON.parse(localStorage.getItem("SoldUtilizator"));
    let refresh=true
    if (soldUtilizatorUltimUpdate && soldUtilizatorUltimUpdate.dataUltimaActualizare){
        let oraActuala=parseInt(moment().format("YYYYMMDDhh")) 
        let dataUltimaActualizare=parseInt(soldUtilizatorUltimUpdate.dataUltimaActualizare)
        if (oraActuala-dataUltimaActualizare<INTERVAL_REFRESH_LOCURI_CONSUM_ORE){
        refresh=false
        }
    
        else{
        setRetrySold(1);
        }
    }
    else if(retrySold > 5){
      refresh=false
    }

    if (refresh){
      try{
          let soldUtilizator= await datePortal("aducSold",null,setEroare)
          let soldUtilizatorUltimUpdate ={ dataUltimaActualizare: moment().format("YYYYMMDDhh"),
              "sold":soldUtilizator
            }
            localStorage.setItem("SoldUtilizator",JSON.stringify(soldUtilizatorUltimUpdate))       
        
      } catch(error){
          setRetrySold(retrySold+1);
        }

      }
    };
  
    const aducSoldPeUtilizatorDinLocalStorage= async function(){
      let soldUtilizatorUltimUpdate=JSON.parse(localStorage.getItem("SoldUtilizator"));
      if (!soldUtilizatorUltimUpdate){
        soldUtilizatorUltimUpdate=[{"sold":[]}]
      }
      if ( JSON.stringify(soldUtilizatorUltimUpdate.sold) !== JSON.stringify(soldUtilizator)){
        setSoldUtilizator([...soldUtilizatorUltimUpdate.sold]); 
      }
      setSoldIncarcat(true);
  }



  React.useEffect(() => {
    (async()=>{
     await aducSoldPeUtilizatorApi();
     await aducSoldPeUtilizatorDinLocalStorage(); 
    })()
  },[]);



  const handleChangePartener = (event) => {
    setPartenerSelectat(event.target.value);
  };


  React.useEffect(() => {
    filreazaSoldulPentruUtilizatorulCurent();    
 },[partenerSelectat,soldUtilizator]);


 React.useEffect(() => {
  arataSold(); 
},[soldPartenerSelectat]);

React.useEffect(() => {
  if (parametri.locuriConsumUtilizator.length>0 &&  soldPartenerSelectat && soldPartenerSelectat !== '' && partenerSelectat === ''){
    setPartenerSelectat(parametri.locuriConsumUtilizator[0].PoD); 
    arataSold(parametri.locuriConsumUtilizator[0].PoD);
  }
    
},[parametri.locuriConsumUtilizator,soldPartenerSelectat]);

 
function plataFactura(index){
  setArataModalPlata(true);
  setIndexFacturaDePlata(parseInt(index));
}

  function filreazaSoldulPentruUtilizatorulCurent() {
    let soldPartenerSelectat=[];
    soldUtilizator.filter(soldClientCurent=>{
     let locuriDeConsumClient = soldClientCurent.locuriConsum
     locuriDeConsumClient.filter(soldPartenerLocConsum=>{
      if (soldPartenerLocConsum.pod===partenerSelectat){
        soldPartenerSelectat=soldPartenerLocConsum.sold;
      }
     })
    })

    setSoldPartenerSelectat(soldPartenerSelectat);
  }

  let arataPartener = parametri.locuriConsumUtilizator.map(locConsum => (
    <MenuItem value={locConsum.PoD}>{locConsum.PoD+'-'+locConsum.Adresa}</MenuItem>
  ));

  function arataSold(){

    let aduSold=null;

    if(soldPartenerSelectat.length>0){
    
      aduSold = soldPartenerSelectat.map((afiseaza,index) => (
        <TableRow
                  key={afiseaza.sold}
                  sx={8}
                >
                  <TableCell component="th" scope="row" align="center">
                    {afiseaza.contContract}
                    </TableCell>
                  <TableCell align="center">{afiseaza.contractFurnizare}</TableCell>
                  <TableCell align="center">{afiseaza.sold}</TableCell>
                  <TableCell align="center">
                    {(afiseaza.sold <= 0 ?
                      
                      <ColorButton
                      key={index}
                      id={index}
                      variant="contained"
                      size="large"
                      disabled
                      onClick= {(el)=>{
                        plataFactura(el.currentTarget.id)}}
                    >
                      Plateste
                    </ColorButton>
                            :
                              
                                <ColorButton
                                 key={index}
                                 id={index}
                                 variant="contained"
                                 size="large"
                                 
                                 onClick= {(el)=>{
                                   plataFactura(el.currentTarget.id)}}
                               >
                                 Plateste
                               </ColorButton>
                    )}
                  
                  </TableCell>
                </TableRow>


      ));
    }
    setArataSoldPartener(aduSold);
  }
  
  if(soldIncarcat){
  return (
    <div style={{paddingTop:'40px',margin: '30px'}}>
    {/* {JSON.stringify(soldUtilizator)} */}
    <FormControl fullWidth>
          <InputLabel id="alegePartener">Alegeti Partener:</InputLabel>
              <Select
                  labelId="alegePartener"
                  id="demo-simple-select"
                  value={partenerSelectat}
                  label="alegePartener"
                  onChange={handleChangePartener}
              >
                {arataPartener}
              </Select>
          </FormControl>
          {arataSoldPartener?<Box style={{paddingTop: '40px', paddingLeft: '50px', paddingRight: '50px'}}> 
        <TableContainer>
          <Table sx={12}  aria-label="simple table">
            <TableHead>
              <TableRow >
                <TableCell align="center">Cont contract:</TableCell>
                <TableCell align="center">Contract furnizare:</TableCell>
                <TableCell align="center">Sold:</TableCell>  
                <TableCell align="center"></TableCell>                   
              </TableRow>
            </TableHead>
            <TableBody>
            {arataSoldPartener}
            </TableBody>
          </Table>
        </TableContainer>
        </Box>:<h3 style={{textAlign:'center'}}>Nu aveți sold restant.</h3>}
    <div style={{ padding: '50px', display: "flex", justifyContent: "center", alignItems: "center", border: ' 0' }}>
    {/* <iframe scrolling="no" style={{width: '100%' , height: '1300px', border: '0'}} class="responsiveIframe" title="Inline Frame Example" src="https://www1.premierenergy.ro/plata/index.html"/> */}
    {arataModalPlata && <PlatesteFactura 
    soldPartenerSelectat={soldPartenerSelectat[indexFacturaDePlata]}
    arataModalPlata={ arataModalPlata}
    setArataModalPlata ={setArataModalPlata}
    /> }
    </div>
    </div>
    );
}
else {
  return (
    <div style={{paddingTop:'30px'}}>
    <h1 style={{textAlign: 'center'}}>Vă rugăm așteptați!</h1>
  <CircularProgress style={{margin: '0', position: 'absolute', top: '150px', left: '48%', width: '40px'}}/>
  </div>
  )
}
};
 
export default withAppInsights(SoldRapid);