import { Box, Select, Grid, IconButton, InputLabel, FormControl,MenuItem,  Button, TableContainer, Table, TableHead, TableRow, TableBody, TableCell } from '@material-ui/core';
import React from 'react';
import { datePortal } from '../api/api';
import { AlternateEmail, CloudDownloadRounded } from '@material-ui/icons';
import { styled } from '@material-ui/core';
import withAppInsights from '../AppInsights';
import { Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress } from '@material-ui/core';
import download from '../images/download.png';
import { makeStyles} from "@material-ui/core/styles";
import downloadDisable from '../images/downloadDisable.png';

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#FF9241'),
  borderRadius: '',
  backgroundColor: '#FF9241',
  color: '#FFFFFF',
  '&:hover': {
    backgroundColor: '#ffaf74',
  },
  '&:focus': {
    backgroundColor: '#ffaf74',
    textColor: '#FFFFFF',

  },
}));


const useStyles = makeStyles(theme => ({
  efectSelectat: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e8ecf0',
      borderColor: '#e8ecf0',
      
    },
},
efectSelectatDisable: {
  cursor: 'pointer',
  '&:hover': {
    backgroundColor: '#F8F8F8',
    borderColor: '#F8F8F8',
    
  },
} 
}))



function AratafacturiPartenerCmp(parametri) {
    const [arataFacturiPartener, setArataFacturiPartener] = React.useState(null);
    const [facturiClient, setFacturiClient] = React.useState([]);
    const [facturiUtilizator, setFacturiUtilizator] = React.useState([]);
    const [eroare,setEroare] = React.useState({"status":false});
    const [partenerSelectat, setPartenerSelectat] = React.useState(parametri.locuriConsumUtilizator[0]?parametri.locuriConsumUtilizator[0].PoD:'');
    const [facturiIncarcate, setFacturiIncarcate] = React.useState(false);
    const [open, setOpen] = React.useState(false);

    const classes = useStyles()

    const handleChangePartener = (event) => {
      setPartenerSelectat(event.target.value);
    };
  
 
   React.useEffect(() => {
     if(eroare.status){
      //  alert(eroare.mesaj)
       ArataEroarea();
     }    
  },[eroare]);

    const handleOpen = () => {
      setOpen(true);
    };
    const handleClose = () => {
      setOpen(false);
      setEroare(false);
    };
    
  //   const aduFacturiClientDinLocalStorage= async function(){
  //     let facturiPartenerUltimUpdate=JSON.parse(localStorage.getItem("FacturiUtilizator"));
  //     if (!facturiPartenerUltimUpdate){
  //       facturiPartenerUltimUpdate={"Facturi":[]}
  //     }
  //     if ( JSON.stringify(facturiPartenerUltimUpdate.Facturi) !== JSON.stringify(facturiClient)){
  //       setFacturiUtilizator([...facturiPartenerUltimUpdate.Facturi]); 
  //     }
  //     // setFacturiIncarcate(true);
  // }
  
  
    // React.useEffect(() => {
    //    aduFacturiClientDinLocalStorage();    
    // },[]);

    React.useEffect(() => {
      arataFacturile();    
  //  },[parametri.facturiClient,facturiPartenerSelectat]);
},[facturiClient]);



   React.useEffect(() => {
    filreazaFacturilePentruUtilizatorulCurent();    
 },[partenerSelectat,parametri.facturiClient]);

 React.useEffect(() => {
  if (parametri.locuriConsumUtilizator.length>0 && partenerSelectat === ''){
    setPartenerSelectat(parametri.locuriConsumUtilizator[0].PoD); 
    // arataFacturile(parametri.locuriConsumUtilizator[0].Partener);
  }
    
},[parametri.locuriConsumUtilizator]);

    

  function filreazaFacturilePentruUtilizatorulCurent() {
    let facturiPartenerSelectat=[]
    parametri.facturiClient.filter(facturiClientCurent=>{
      let facturiLocConsum = facturiClientCurent.facturi;
      facturiLocConsum.filter(contractFurnizareClient=>{
        if (contractFurnizareClient.PoD===partenerSelectat){
          facturiPartenerSelectat.push(contractFurnizareClient);
        }
      })
    })

    setFacturiClient(facturiPartenerSelectat);
  }
    



   function onClickDownloadPDF(nrFactura) {
    downloadPDF(nrFactura);
  
  }
  
  
  
   const downloadPDF = async function(nrFactura) {

    try{
    
    let pdf = await  datePortal("aduFacturaPDF",nrFactura,setEroare);

    
    var isSafari =
      navigator.vendor &&
      navigator.vendor.indexOf('Apple') > -1 &&
      navigator.userAgent &&
      navigator.userAgent.indexOf('CriOS') == -1 &&
      navigator.userAgent.indexOf('FxiOS') == -1;

    
    // const linkSource = 'data:application/pdf;base64,'+ encodeURIComponent(pdf);
    // // var element = document.createElement('a');
    // //         var clearUrl = base64.replace(/^data:application/pdf\/\w+;base64,/, '');
    // //         element.setAttribute('href', 'data:application/octet-stream;base64,' + encodeURIComponent(clearUrl));
    // //         element.setAttribute('download', 'filename.jpg');
    // //         document.body.appendChild(element);
    // //         element.click();
    // //         document.body.removeChild(element)
    // const downloadLink = document.createElement("a");
    // //type="application/pdf"
    // downloadLink.setAttribute('href', linkSource);
    // // downloadLink.download = nrFactura +".pdf";
    // // downloadLink.id=nrFactura;
    // // downloadLink.target="_blank"
    // //downloadLink.setAttribute('download', nrFactura + ".pdf");
    // downloadLink.setAttribute('id',nrFactura);
    // downloadLink.setAttribute('type',"application/pdf");
    // downloadLink.setAttribute('target','_blank');
    // downloadLink.click();

    var byteCharacters = atob(pdf);
    var byteNumbers = new Array(pdf.length);
    for (var i = 0; i < byteCharacters.length; i++) {
       byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    var byteArray = new Uint8Array(byteNumbers);
    //var file = new Blob([byteArray], { type: 'application/pdf;base64' });
    var file = new Blob([byteArray], { type: 'application/pdf' });
    
    var fileURL = URL.createObjectURL(file);
    //window.open(fileURL);
  
    //const linkSource = 'data:application/pdf;base64,'+ encodeURIComponent(pdf);
    const downloadLink = document.createElement("a");
    //type="application/pdf"
    downloadLink.setAttribute('href', fileURL);
    //downloadLink.download = nrFactura +".pdf";
    downloadLink.id=nrFactura;
    if (!isSafari){
      downloadLink.target="_blank"
    }
    downloadLink.click();

    

        //If browser is Safari, use a Reader to display the PDF in the previously opened window
        if (isSafari) {
          var reader = new FileReader();
          reader.onloadend = function (e) {
            var newWindow = window.open('', '_blank');
            newWindow.location.href = reader.results;
          };
          reader.readAsDataURL(file);
        }  
      
   //downloadLink.parentNode.removeChild(downloadLink);
    //downloadLink.remove();

  //   let fileName=nrFactura + ".pdf"
    
  //   let pdfWindow = window.open('about:blank', fileName);
    
  //   pdfWindow.document.write("<html<head><title>"+fileName+"</title><style>body{margin: 0px;}</style></head>");
  //<embed src="/my-account/invoices/pdf?invoice_id=5650596777&amp;d-d72afea30bb367519afe468bb5457994=eDh6Smo3V25kK0p0VWlkVEhmZktiZz09" type="application/pdf">
  //   pdfWindow.document.write(
  //     "<body><iframe width='99%' height='99%' src='data:application/pdf;base64, " +
  //     encodeURI(pdf) + "'></iframe></body>"
  // )
  // pdfWindow.document.close();
    
  } catch(error){
    setEroare({
      "status":true,
      "mesaj":error.message.mesaj
    }) 
    handleOpen();
    // ArataEroarea();
    // alert(eroare.mesaj);
      
      // handleOpen();

    
    
  }
}

function ArataEroarea(){

  return(
    <div>
      <Modal 
      open={open}
      onClose={handleClose}
      >
        <IconButton style={{ color: '#428dff', right: '20px', top: '5px', position: 'absolute', transformOrigin: '0% 100%' }} color="primary" aria-label="add to shopping cart">
          <CloseIcon onClick={handleClose} />
        </IconButton>
      <h4>{eroare.mesaj}</h4>
      </Modal>
    </div>
  )
}



  let arataPartener = parametri.locuriConsumUtilizator.map(locConsum => (
    <MenuItem value={locConsum.PoD}>{locConsum.PoD+'-'+locConsum.Adresa}</MenuItem>
  ));


  let aduFacturile=null;
  
  //function arataFacturile(Partener) {}

  function arataFacturile() {


    let aduFacturile=null;
     
      if(facturiClient.length>0){
                 aduFacturile = facturiClient.map(factura => (
      
                  <TableRow
                  key={factura.numarFactura}
                  sx={8}
                >
                  <TableCell component="th" scope="row" align="center">
                    {factura.numarFactura}
                    </TableCell>
                  <TableCell align="center">{factura.dataEmiterii.slice(0,10)}</TableCell>
                  <TableCell align="center">{factura.dataScadenta.slice(0,10)}</TableCell>
                  <TableCell align="center">{factura.totalDePlata}</TableCell>
                  <TableCell align="center">
                  {factura.existaPDF === false ? <img alt='butonDescarca'
                            key={'DownloadFactua'+factura.numarFactura}
                            id={factura.numarFactura}
                            src={downloadDisable}
                            className={classes.efectSelectatDisable}
                            /> : <img alt='butonDescarca'
                            key={'DownloadFactua'+factura.numarFactura}
                            id={factura.numarFactura}
                            src={download}
                            className={classes.efectSelectat}
                            onClick={(element)=>onClickDownloadPDF(element.currentTarget.id)}
                            /> }
                            

                  </TableCell>
                </TableRow>

        ));
        
      }
      setArataFacturiPartener(aduFacturile);
    
    
  }

  // if(facturiIncarcate){
  return (
    <div style={{paddingTop:70, maxWidth:"80%", margin:"auto"}}>
    <Box xs={4}>
      </Box> 
          <FormControl fullWidth >
          <InputLabel id="alegePartener">Alegeti Partener:</InputLabel>
              <Select
                  labelId="alegePartener"
                  id="demo-simple-select"
                  value={partenerSelectat}
                  label="alegePartener"
                  onChange={handleChangePartener}
              >
                {arataPartener}
              </Select>
          </FormControl>
          {facturiClient.length === 0 ?
          <Grid style={{paddingTop: '5rem'}} container justifyContent="center"
          alignItems="center"><Grid item ><b>Nu aveți facturi pentru acest loc de consum</b></Grid></Grid>
          :
          <Box style={{paddingTop: '40px', paddingLeft: '50px', paddingRight: '50px'}}> 
        <TableContainer>
          <Table sx={12}  aria-label="simple table">
            <TableHead>
              <TableRow >
                <TableCell align="center">Numar factura:</TableCell>
                <TableCell align="center">Data emiterii:</TableCell>
                <TableCell align="center">Data scadenta:</TableCell>  
                <TableCell align="center">Total de plata:</TableCell> 
                <TableCell align="center"></TableCell>                   
              </TableRow>
            </TableHead>
            <TableBody>
            {arataFacturiPartener}
            </TableBody>
          </Table>
        </TableContainer>
        </Box>
        }
        {/* {eroare ? <h4>{mesaj}</h4> : null} */}
      {/* {eroare.status ? <ArataEroarea/> : null} */}
      {/* <ArataEroarea/> */}
    </div>
  )}
// )}
// else {
//   return(
//     <div style={{marginTop:'2rem'}}>
//     <h1 style={{textAlign: 'center'}}>Vă rugăm așteptați!</h1>
//   <CircularProgress style={{margin: '0', position: 'absolute', left: '48%', width: '40px', transform: 'translateY(-50%)'}}/>
//   </div>
//   )

// }}

export const AratafacturiPartener=withAppInsights(AratafacturiPartenerCmp)