import jwtDecode from "jwt-decode";



export function dateUtilizatorDinToken(){
    const token = localStorage.getItem('id_token');
    const decodedToken = jwtDecode(token);
    return {
        "numeFamilie":decodedToken.family_name,
        "nume":decodedToken.given_name
    }
    
}


export function userDinToken(){
    const token = localStorage.getItem('id_token');
    const decodedToken = jwtDecode(token);
    return decodedToken.sub    
}

export function esteAutentificat(){
    setareToken()
    const token = localStorage.getItem('id_token');
    if(!token) {
        return false;
    }
    const decodedToken = jwtDecode(token);
    const currentDate = new Date();
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
        localStorage.removeItem('id_token');
        console.log("Token expired.");
        return false;
    } 
    return true;
}

export function redirectToLogin(){
    window.location.href = process.env.REACT_APP_SECURITY_LOGIN_PATH
}


export function logout(){
    localStorage.removeItem('id_token');
    localStorage.removeItem('locuriConsum');
    localStorage.removeItem('consum');
    localStorage.removeItem('FacturiUtilizator');
    localStorage.removeItem('SoldUtilizator');
    window.location.href = process.env.REACT_APP_SECURITY_LOGOUT_PATH
}


export function setareToken(){

    let url=window.location.href;
    let startChar=url.indexOf('#id_token=');
    if (startChar>0){
        startChar=startChar+10;
        localStorage.setItem('id_token',url.substring(startChar));
        localStorage.removeItem('locuriConsum');
        localStorage.removeItem('consum');
        localStorage.removeItem('FacturiUtilizator');
        localStorage.removeItem('SoldUtilizator');
        window.location.href = "/"
    }
}

export function verificaRedirectModificareParola(){
    let url=window.location.href;
    let startChar=url.indexOf('error_description=AADB2C90118');
    if (startChar>0){
        window.location.href = process.env.REACT_APP_SECURITY_RESET_PASSWORD_PATH
    }
}




export function autentificare(){
    verificaRedirectModificareParola();

}
