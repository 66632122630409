import React from 'react';
import { consumPartener } from './client';
import { CircularProgress, MenuItem , Select } from '@material-ui/core';
import { Box } from '@material-ui/core';
import { InputLabel } from '@material-ui/core';
import { FormControl } from '@material-ui/core';
import { Grid } from '@material-ui/core';
import { Paper } from '@material-ui/core';
import { styled } from '@material-ui/core';
import { Table } from '@material-ui/core';
import { TableBody } from '@material-ui/core';
import { TableCell } from '@material-ui/core';
import { TableContainer } from '@material-ui/core';
import { TableHead } from '@material-ui/core';
import { TableRow } from '@material-ui/core';
import moment from 'moment';
import { datePortal } from '../api/api';
import withAppInsights from '../AppInsights';




const Item = styled(Paper)(({ theme }) => ({
  // ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
  margin: '40px',
  padding: 3,
}));
 
const INTERVAL_REFRESH_CONSUM_ORE=parseInt(process.env.REACT_APP_INTERVAL_REFRESH_CONSUM_ORE);



function IndexConsum(parametri) {

  const [locConsumSelectat, setLocConsumSelectat] = React.useState('');
  const [detaliiConsum, setDetaliiConsum] = React.useState('');
  const [consumUtilizator, setConsumUtilizator] = React.useState('');
  const [consumIncarcat, setConsumIncarcat] = React.useState(false);

  const [eroare,setEroare] = React.useState({"status":false});



  const aduConsumUserDinLocalStorage= async function(){
    let consumUltimUpdate=JSON.parse(localStorage.getItem("consum"));
    if (!consumUltimUpdate){
      consumUltimUpdate={"consum":[]}
    }
    if ( consumUltimUpdate.consum.length !== detaliiConsum.length){
      setConsumUtilizator([...consumUltimUpdate.consum]); 
    }
    setConsumIncarcat(true);
    
}

const aduConsumUserApi= async function(){
  let consumUltimUpdate=JSON.parse(localStorage.getItem("consum"));
  let refresh=true
  if (consumUltimUpdate && consumUltimUpdate.dataUltimaActualizare){
      let oraActuala=parseInt(moment().format("YYYYMMDDhh")) 
      let dataUltimaActualizare=parseInt(consumUltimUpdate.dataUltimaActualizare)
      if (oraActuala-dataUltimaActualizare<INTERVAL_REFRESH_CONSUM_ORE){
      refresh=false
      }
  }
  if (refresh){
    let consum= await  datePortal("aducConsum",null,setEroare)
    let consumUltimUpdate ={ dataUltimaActualizare: moment().format("YYYYMMDDhh"),
      "consum":consum
    }
    localStorage.setItem("consum",JSON.stringify(consumUltimUpdate))
  }
}



  const consum = async () => {
    if (parametri.autentificat){
      await aduConsumUserApi();
      await aduConsumUserDinLocalStorage();    
    }
  };
    
  React.useEffect(() => {
    
    consum();   
    // setLocConsumSelectat(); 
  },[]);

  React.useEffect(() => {
    if (parametri.locuriConsumUtilizator.length>0 &&  consumUtilizator && consumUtilizator !== '' && locConsumSelectat === ''){
      setLocConsumSelectat(parametri.locuriConsumUtilizator[0].PoD); 
      arataDetaliiConsum(parametri.locuriConsumUtilizator[0].PoD);
    }
      
  },[parametri.locuriConsumUtilizator,consumUtilizator]);


    let arataLocConsum = parametri.locuriConsumUtilizator.map(locConsum => (
      <MenuItem value={locConsum.PoD}>{locConsum.PoD+"-"+locConsum.Adresa}</MenuItem>
    ));
    

  const handleChangeLocConsum = (event) => {
    setLocConsumSelectat(event.target.value);
    arataDetaliiConsum(event.target.value);
  };

    function arataDetaliiConsum(codlocConsum) { 
      
      var locConsumCurent = consumUtilizator.filter(locConsum => locConsum.codlocConsum === codlocConsum)[0];
      let consumMapat=  
        <Grid style={{paddingTop: '5rem'}} container justifyContent="center"
        alignItems="center"><Grid item ><b>Nu aveți consum pentru acest loc de consum</b></Grid></Grid>
      
      if (locConsumCurent){
       consumMapat=locConsumCurent.contracte.map(contract => (<Grid item style={{ marginLeft: 'auto', marginRight: 'auto'}} xs={8}>
      <Item value={contract.nrContract}>{" Numar contract: "+contract.nrContract+" Furnizor: "+contract.furnizor+" Serviciu: "+contract.serviciu+" Categorie Consum: "+contract.CategorieConsum}</Item>
      <Box> 
        <TableContainer>
          <Table sx={12}  aria-label="simple table">
            <TableHead>
              <TableRow >
                <TableCell align="center">Data citire:</TableCell>
                <TableCell align="center">Consum(M3):</TableCell>
                <TableCell align="center">Consum(MW/h):</TableCell>                      
              </TableRow>
            </TableHead>
            <TableBody>
              {contract.consum.map( consumCurent =>(
               <TableRow
               key={consumCurent.dataCitire}
               sx={8}
             >
               <TableCell component="th" scope="row" align="center">{consumCurent.dataCitire.slice(0,10)}</TableCell>
               <TableCell align="center">{consumCurent.consumM3}</TableCell>
               <TableCell align="center">{consumCurent.consumMWH}</TableCell>
             </TableRow>
              )) }   
            </TableBody>
          </Table>
        </TableContainer>
        </Box>
  </Grid>
      ))
    }
      
      setDetaliiConsum(consumMapat);  
    };



    if (consumIncarcat){
      return (
        <Box sx={{ margin: '30px 0px 0px 0px', paddingTop: '20px', minWidth: 120 }}>
        <Box style={{margin: '20px'}}>
          <FormControl fullWidth>
          <InputLabel id="alegeLocConsum" >Alegeti locul de consum:</InputLabel>
          <Select
            labelId="alegeLocConsum"
            id="alegeLocConsum"
            value={locConsumSelectat}
            label="alegeLocConsum"
            onChange={handleChangeLocConsum}
          >
            {arataLocConsum}
          </Select>
          <Box sx={{ flexGrow: 1 }}>
          <Grid container spacing={1}>
          {detaliiConsum}
          </Grid>
          </Box>
        </FormControl>
        </Box>
      </Box>  
      );
    }
    else {
      return (
        <div style={{ paddingTop: '30px'}}>
        <h1 style={{ textAlign: 'center'}}>Vă rugăm așteptați!</h1>
      <CircularProgress style={{margin: '0', position: 'absolute', top: '150px', left: '48%', width: '40px', transform: 'translateY(-50%)'}}/>
      </div>
      )
    }
  };

 
export default withAppInsights(IndexConsum);