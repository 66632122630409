import React from 'react';
import ModalAdaugaLocConsum from '../adaugaLocConsum/adaugaLocConsum';
import { Box, Button, TableCell, TableRow, TableBody, Table, TableContainer, TableHead } from '@material-ui/core';
import { styled } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import { datePortal } from '../api/api';
import moment from 'moment';
import withAppInsights from '../AppInsights';
import {appInsights} from '../AppInsights';
import {userDinToken} from '../security/sec'
import bin from '../images/bin.png';
import { makeStyles} from "@material-ui/core/styles";





const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#428DFF'),
  borderRadius: '',
  backgroundColor: '#428DFF',
  '&:hover': {
    backgroundColor: '#67a3ff',
  },
  '&:focus': {
    backgroundColor: '#67a3ff',
    textColor: '#000000',

  },
  
}));

const useStyles = makeStyles(theme => ({
  efectSelectat: {
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: '#e8ecf0',
      borderColor: '#e8ecf0',
      
    },
} 
}))


function ContulMeu(parametri) {
  const properties = {userId : userDinToken()}
   const[locuriConsumUtilizator,setLocuriConsumUtilizator] = React.useState([]);
   const [locConsumSters,setLocConsumSters] = React.useState({});
   const [eroare,setEroare] = React.useState({"status":false});
   const [refreshData, setRefreshData] = React.useState(true);

   const classes = useStyles()


   appInsights.trackPageView({name:'LocuriDeConsum'},properties)
  async function actualizareLocuriConsumDupaAdaugare(){
    localStorage.removeItem('locuriConsum');
    localStorage.removeItem('consum');
    localStorage.removeItem('FacturiUtilizator');
    localStorage.removeItem('SoldUtilizator');
    await parametri.improspatareDateDupaStergere();
    setRefreshData(false);
  }

  
  const aduLocuriConsumCuAdresaDinLocalStorage= async function(){
    let locuriConsumUltimUpdate=JSON.parse(localStorage.getItem("locuriConsum"));
    if (!locuriConsumUltimUpdate){
      locuriConsumUltimUpdate={"locuriConsum":[]}
    }
    if ( locuriConsumUltimUpdate.locuriConsum.length !== locuriConsumUtilizator.length){
      setLocuriConsumUtilizator([...locuriConsumUltimUpdate.locuriConsum]); 
    }
    
}

async function  onClickStergeLocConsum(id,locConsum,partener) {
  const locConsumDeSters = {

    "locConsum":locConsum,

    "partener":partener

}



  let locuriConsum = await datePortal("stergeLocConsum",locConsumDeSters,setEroare);
    
    actualizareLocuriConsumDupaAdaugare();
}



// React.useEffect(
//     async ()=>{
//     await aduLocuriConsumCuAdresaDinLocalStorage()    ;
//     });

  React.useEffect(() => {
     aduLocuriConsumCuAdresaDinLocalStorage();    
  });

  React.useEffect(() => {
    aduLocuriConsumCuAdresaDinLocalStorage();    
 },[refreshData]);


  return (
    <div>
      <Box fullWidth align="center" style={{paddingTop: '50px', marginLeft: '70px', marginRight: '70px'}}>
      <TableContainer>
      <Table sx={12}  aria-label="simple table">
            <TableHead>
              <TableRow >
                <TableCell align="center">CLC:</TableCell>
                <TableCell align="center">Adresa:</TableCell> 
                <TableCell align="center">Categorie Consum:</TableCell> 
                <TableCell align="center"></TableCell>                   
              </TableRow>
            </TableHead>
            <TableBody>
            {locuriConsumUtilizator.map( locConsum =>{return (<TableRow
                  key={locConsum.PoD}
                  sx={8}
                >
                  <TableCell component="th" scope="row" align="center">
                    {locConsum.PoD}
                    </TableCell>
                  <TableCell align="center">{locConsum.Adresa}</TableCell>
                  <TableCell align="center">{locConsum.CategorieConsum}</TableCell>
                  <TableCell align="center">
                  <img alt='butonSterge'
                            key={'StergeLocConsum'+locConsum.PoD}
                            locConsum={locConsum.PoD}
                            partener={locConsum.Partener}
                            id={locConsum.Partener+"-"+locConsum.PoD+"-butonStergere"}
                            src={bin}
                            className={classes.efectSelectat}
                            onClick={(element)=>onClickStergeLocConsum(element.currentTarget.id, element.currentTarget.getAttribute('locconsum'), element.currentTarget.getAttribute('partener'))}
                            />

                  </TableCell>
                </TableRow>)})}
            </TableBody>
          </Table>
        </TableContainer>
        
    </Box>
    <div style={{paddingTop: '20px', display: "flex", justifyContent: "center", alignItems: "center"}}>
    <ModalAdaugaLocConsum
    setLocuriConsumUtilizator={actualizareLocuriConsumDupaAdaugare}
    />
    </div>
    </div>
  );
}
 
export default withAppInsights(ContulMeu);