import * as React from 'react';
import { Modal, Button, Box, TextField, Typography } from '@material-ui/core';
import { dateUtilizatorDinToken, logout } from '../security/sec';
import Menu from '../images/Menu.png';
import { makeStyles} from "@material-ui/core/styles";



const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '50%',
  bgcolor: 'background.paper',
  border: '2px solid #fd7e14',
  boxShadow: 24,
  pt: 2,
  px: 4,
  pb: 3,
  maxWidth: '20rem'
};

const useStyles = makeStyles(theme => ({
  efectSelectat: {

    '&:hover': {
      backgroundColor: '#ffffff30',
      borderColor: '#ffffff30',
      
    },
} 
}))




function DateUtilizator(){

    const utilizator = dateUtilizatorDinToken();


    return (
        <div style={{textAlign: 'center'}}>
            <div>
            <TextField
            style={{textAlign: 'center'}}
            inputProps={{min: 0, style: { textAlign: 'center' }}}
            disabled
            id="outlined-helperText-NumeFamilie"
            label="Nume"
            defaultValue={utilizator.numeFamilie}
            />
            </div>
            <div>
            <TextField
            style={{textAlign: 'center'}}
            inputProps={{min: 0, style: { textAlign: 'center' }}}
            disabled
            id="outlined-helperText-Nume"
            label="Prenume"
            defaultValue={utilizator.nume}
            />
            </div>

        </div>

    )

}




export default function ModalDateUtilizator() {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };


  const deconecrare = () => {
        logout();
    };

    const classes = useStyles()
  return (
    <div>
      
      
      <img alt='Meniu' className={classes.efectSelectat}   style={{height: '50px', right: '20px', width: '50px', top:'15px', position: 'absolute' }} src={Menu} onClick={handleOpen} />
      
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="parent-modal-title"
        aria-describedby="parent-modal-description"
      >
        <Box sx={{...style}}>
          <Typography style={{textAlign:'center'}} id="parent-modal-deconectare" variant="h6" component="h2">Informații utilizator:</Typography>
          <p id="parent-modal-deconectare">
            <DateUtilizator/>
          </p>
          <div style={{textAlign: 'right', border: '20px'}}>
          <Button style={{color: '#fd7e14'}} onClick={deconecrare}> Deconectare</Button>
          </div>
        </Box>
      </Modal>
    </div>
  );
}
