import React from 'react';
import {redirectToLogin } from '../security/sec';
import {Button, Container} from '@material-ui/core';
import { autentificare, logout } from '../security/sec';
import ModalDateUtilizator from '../user/User';
import { useHistory } from 'react-router';
import { KeyboardArrowLeft } from '@material-ui/icons';
import { AppBar, Box, Grid , Toolbar } from '@material-ui/core';
import { styled } from '@material-ui/core';
import logoPremierEnergyHome from '../images/logoPremierEnergyHome.png';
import { makeStyles} from "@material-ui/core/styles";
import butonInapoi from '../images/butonInapoi.png';
import {ExitToApp} from '@material-ui/icons';



const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText('#d9e8ff'),
  width: '10px',
  backgroundColor: '#d9e8ff',
  '&:hover': {
    backgroundColor: '#ecf3ff',
  },
}));

const ColorToolbar = styled(Toolbar)(({ theme }) => ({
  color: theme.palette.getContrastText('#1e456d'),
  padding: '0 px',
  border: '0px solid',
  height: '90px',
  backgroundColor: '#1e456d',
  // maxWidth: '100%',
  // minWidth:'100%',
  // paddingRight: '100%',
  
}));

const useStyles = makeStyles(theme => ({
  efectSelectat: {

    '&:hover': {
      backgroundColor: '#ffffff30',
      borderColor: '#ffffff30',
      
    },
} 
}))




export function Header(parametri) {
  const history = useHistory();
const routeChange = () => {
  let path = '/';
  verificaEsteInRadacina('home');
  history.push(path);

}
  

  const verificaEsteInRadacina = (paginaDestinatie) => {
    if(paginaDestinatie === 'home' && parametri.esteInRadacina !== true ){
      parametri.setEsteInRadacina(true);
      return true
    }
    var url = window.location.href;
    const locatieCurenta = url.substring(url.indexOf('/', 10)+1);
    if(locatieCurenta && locatieCurenta.length > 5 && parametri.esteInRadacina !== false){
      parametri.setEsteInRadacina(false);
      return false
    }
  return true
  }

  const deconectare = () => {
    logout();
};

  const classes = useStyles()
  autentificare();
  verificaEsteInRadacina();
  if (!parametri.autentificat) {
    return (
    <div style={{background: 'solid 1px #1e456d '}}>
      <toolBar/>
        <Button style={{ right: '20px',top: '5px', position: 'absolute', color: '#428dff' }} variant="outlined" onClick={() => redirectToLogin()}>login</Button>
    </div>
    )
  }
  return (
        <div style={{paddingBottom: '50px'}}>
          <span>
        <Box display="flex" justifyContent="center">
          <AppBar position= 'fixed'>
          <ColorToolbar variant="dense" >
          <Box display="flex" justifyContent="space-between" flexGrow={1}>
        
          <img xs={1} alt='Premier Energy' style={{height: 40, paddingTop:'17px',paddingBottom: '17px', paddingLeft: '10px', paddingRight: '10px' }} src={logoPremierEnergyHome} />
          </Box>
          {/* {parametri.esteInRadacina ? null : <img alt='butonBack' className={classes.efectSelectat}   style={{height: '35px', left: '20px', width: '35px', top:'25px', position: 'absolute' }} src={butonInapoi} onClick={routeChange} />} */}
            
          {/* <div style={{color: '#428dff', position: 'absolute', left: '1%', top: '20px'}}><ColorButton variant="contained"  startIcon={<KeyboardArrowLeft />} onClick={routeChange}></ColorButton></div> */}
          <Box display="flex" justifyContent="flex-end">
              <Button style={{color: '#ffffff'}} endIcon={<ExitToApp />} onClick={deconectare}>Deconectare</Button>
              </Box> 
            
            </ColorToolbar>
            </AppBar>
      </Box>
      </span>
        </div>
    )
}
 